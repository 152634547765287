import React, { useState } from "react";
import TextButton from "ui/Button/TextButton";
import styles from "./tabs.module.scss";

const Tabs = ({ tabs }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTab = tabs[activeTabIndex];

  return (
    <div className={styles.tabs}>
      <nav>
        <ul className={styles.tabNav}>
          {tabs.map((tab, i) => (
            <li
              key={i}
              className={
                styles.tabNavItem +
                (activeTabIndex === i ? " " + styles.active : "")
              }
            >
              <TextButton
                onClick={() => setActiveTabIndex(i)}
                className={styles.tabNavItemLink}
              >
                {tab.title}
              </TextButton>
            </li>
          ))}
        </ul>
      </nav>
      <div className={styles.tabContent}>{activeTab.content}</div>
    </div>
  );
};

export default Tabs;
