import { AuthContext } from "auth/AuthProvider";
import TokenClient from "auth/client/TokenClient";
import { SubmittingElement } from "components/Forms/LoginForm/LoginForm";
import React, { useContext, useEffect, useState } from "react";
import ChangePasswordForm from "components/Forms/ChangePasswordForm/ChangePasswordForm";
import { Section, Layout } from "ui/";

const ChangePassword = (props) => {
  const queryToken = props.match.params.token;
  const { setToken, token } = useContext(AuthContext);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (queryToken && !token && !submitting) {
      setSubmitting(true);
      new TokenClient()
        .Token({
          token: queryToken,
        })
        .then((response) => {
          setSubmitting(false);
          setToken(response.access_token);
        });
    }
  }, [token, submitting]);
  return (
    <Section>
      <Layout position="topCenter">
        {token ? <ChangePasswordForm /> : <SubmittingElement />}
      </Layout>
    </Section>
  );
};

export default ChangePassword;
