import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Fieldset, Input, InputGroup, Label, Title } from "ui/";
import useCustomForm from "hook/Form/useCustomForm";
import { Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "auth/AuthProvider";

const compileForm = (values) => {
  return { password: values["newPassword"] };
};

const initialValues = { newPassword: "", confirmNewPassword: "" };

const ChangPasswordForm = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { token, apiClient } = useContext(AuthContext);

  const {
    values,
    changed,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    onSubmitting,
    updateError,
  } = useCustomForm({
    initialValues: initialValues,
    onSubmitHandler: async () => {
      if (validForm) {
        await apiClient.ChangePassword(compileForm(values)).then(() => {
          history.push("/estimate-survival");
        });
      }
    },
  });

  const validForm =
    values.confirmNewPassword.length > 0 &&
    values.newPassword.length > 0 &&
    values.newPassword === values.confirmNewPassword;

  const onBlurHandler = (event) => {
    if (values.confirmNewPassword.length > 0) {
      if (values.newPassword === values.confirmNewPassword) {
        updateError("confirmNewPassword", undefined);
      } else {
        updateError("confirmNewPassword", t("Passwords don't match"));
      }
    }

    handleBlur(event);
  };

  const submitEnabled = changed && validForm;

  if (token) {
    return (
      <>
        <Title>{t("Change password")}</Title>
        <form onSubmit={handleSubmit} id="caseform">
          <Fieldset disabled={onSubmitting}>
            <InputGroup>
              <Label htmlFor="newPassword">{t("New Password")}</Label>
              <Input
                type="password"
                id="newPassword"
                name="newPassword"
                required
                onChange={handleChange}
                onBlur={onBlurHandler}
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="confirmNewPassword">
                {t("Confirm New Password")}
              </Label>
              <Input
                type="password"
                id="confirmNewPassword"
                name="confirmNewPassword"
                required
                onChange={handleChange}
                onBlur={onBlurHandler}
              />
              {errors && errors["confirmNewPassword"] && (
                <p>{errors["confirmNewPassword"]}</p>
              )}
            </InputGroup>
            <Button type="submit" disabled={!submitEnabled}>
              {t("Change Password")}
            </Button>
          </Fieldset>
        </form>
      </>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }
};

export default ChangPasswordForm;
