import React from "react";
import styles from "./section.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

const Section = ({ children, bgColor }) => {
  const SectionClassNames = classNames(styles.section, {
    [styles.white]: bgColor === "white",
    [styles.lightWhite]: bgColor === "lightWhite",
    [styles.lightBlue]: bgColor === "lightBlue",
  });

  return <div className={SectionClassNames}>{children}</div>;
};

Section.propTypes = {
  children: PropTypes.node,
  bgColor: PropTypes.oneOf(["white", "lightWhite", "lightBlue"]),
};

export default Section;
