import React from "react";
import styles from "./quickLinks.module.scss";

const QuickLinks = ({ children }) => {
  return (
    <div className={styles.quickLinks}>
      <ul>{children}</ul>
    </div>
  );
};

export default QuickLinks;
