import { DynamicSection } from "page-components/DynamicSection";
import Hero from "page-components/Hero/Hero";
import React from "react";

const Home = ({ page }) => {
  const sections = page ? page.sections : Array(5);

  return (
    <div>
      <Hero />
      {sections.map((section, idx) => {
        return (
          <DynamicSection
            key={idx}
            section={section}
            idx={idx}
            narrow={false}
          />
        );
      })}
    </div>
  );
};
export default Home;
