import React from "react";
import styles from "./sectionWithBackground.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames";

const SectionWithBackground = ({ children, bgUrl }) => {
  const SectionWithBackgroundClassNames = classNames(
    styles.sectionWithBackground
  );

  const style = bgUrl
    ? {
        backgroundImage: `url(${bgUrl})`,
      }
    : {};

  return (
    <div className={SectionWithBackgroundClassNames} style={style}>
      {children}
    </div>
  );
};

SectionWithBackground.propTypes = {
  children: PropTypes.node,
  bgColor: PropTypes.string,
};

export default SectionWithBackground;
