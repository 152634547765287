import React from "react";
import { OrderedList, OrderedListItem, Skeleton } from "ui";
import SectionTitleAndParagraph from "../SectionTitleAndParagraph/SectionTitleAndParagraph";

const SectionTitleParagraphAndValidationList = ({ section, bgColor }) => {
  const list = section ? section.validationList : Array(11);
  return (
    <SectionTitleAndParagraph section={section} bgColor={bgColor}>
      <OrderedList>
        {list.map((l, i) => {
          return (
            <OrderedListItem key={i}>
              {l ? (
                <a
                  href={l.fields.listItemUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {l.fields.listItemName}
                </a>
              ) : (
                <Skeleton />
              )}
            </OrderedListItem>
          );
        })}
      </OrderedList>
    </SectionTitleAndParagraph>
  );
};

export default SectionTitleParagraphAndValidationList;
