import React from "react";
import PropTypes from "prop-types";
import styles from "./layout.module.scss";
import classNames from "classnames";

const Layout = ({ children, position, narrow = false }) => {
  const LayoutClassNames = classNames(styles.layout, {
    [styles.top]: position === "top",
    [styles.topColumn]: position === "topColumn",
    [styles.topCenter]: position === "topCenter",
    [styles.right]: position === "right",
    [styles.left]: position === "left",
    [styles.narrow]: narrow === true,
  });

  return <div className={LayoutClassNames}>{children}</div>;
};

Layout.propTypes = {
  children: PropTypes.node,
  position: PropTypes.oneOf(["top", "topColumn", "topCenter", "right", "left"]),
  narrow: PropTypes.bool || undefined,
};

export default Layout;
