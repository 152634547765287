import { createClient } from "contentful";
import React, { createContext, useEffect, useState } from "react";
import ApiClient from "./client/ApiClient";

export const AuthContext = createContext({
  token: null,
  apiClient: null,
  setToken: null,
  contentfulClient: null,
  userData: null,
});

export function AuthProvider({ children }) {
  const [token, setToken] = useState(null);
  const [apiClient, setApiClient] = useState(null);
  const [contentfulClient, setContentfulClient] = useState(null);
  const [userData, setUserData] = useState(null);
  if (!contentfulClient) {
    setContentfulClient(
      createClient({
        space: "v2haway1vc50",
        accessToken: "d5ouRs9SM-BF4Kg3apaGMp_McXleQBqlyd6LDi-sxmo",
      })
    );
  }
  const provider = {
    token,
    setToken,
    apiClient,
    contentfulClient,
    userData,
  };

  useEffect(() => {
    if (token) {
      if (!apiClient || apiClient.token !== token) {
        const newApiClient = new ApiClient(token);
        newApiClient
          .GetUser()
          .then((user) => setUserData(user))
          .catch(() => setUserData(null));
        setApiClient(newApiClient);
      }
    }
  }, [token]);

  return (
    <AuthContext.Provider value={provider}>{children}</AuthContext.Provider>
  );
}
