import React from "react";
import Skeleton from "ui/Skeleton/Skeleton";
import { Layout, Paragraph, Section, Title } from "ui/";
import ShareCaseForm from "components/Forms/ShareCaseForm/ShareCaseForm";

const ShareACase = ({ page }) => {
  return (
    <div>
      <Section bgColor="lightWhite">
        <Layout position="topCenter">
          <Title>
            {page ? page?.sections[0]?.fields?.title : <Skeleton />}
          </Title>
          <Paragraph color="purple">
            {page ? page?.sections[0]?.fields?.paragraph : <Skeleton />}
          </Paragraph>
        </Layout>
      </Section>

      <Section>
        <Layout narrow={true}>
          <ShareCaseForm />
        </Layout>
      </Section>
    </div>
  );
};

export default ShareACase;
