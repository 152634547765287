import React from "react";
import PropTypes from "prop-types";
import styles from "./heading.module.scss";
import classNames from "classnames";

const Heading = ({ children, color }) => {
  const HeadingClassNames = classNames(styles.heading, {
    [styles.white]: color === "white",
    [styles.purple]: color === "purple",
    [styles.darkPurple]: color === "darkPurple",
  });
  return <h3 className={HeadingClassNames}>{children}</h3>;
};

Heading.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(["white", "purple", "darkPurple"]),
};

export default Heading;
