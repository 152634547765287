import React from "react";
import { AuthContext } from "./AuthProvider";
import ApiClient from "./client/ApiClient";

function useApi(queryToken) {
  const { apiClient } = React.useContext(AuthContext);

  if (queryToken) {
    return new ApiClient(queryToken);
  }

  if (!apiClient) {
    throw Error("Cannot connect to API");
  }

  return apiClient;
}

export default useApi;
