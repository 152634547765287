/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./imageupload.module.scss";

const ImageUpload = ({ id, name }) => {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    let filteredArr = selectedFiles.reduce((acc, current) => {
      const x = acc.find((item) => item.name === current.name);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setValidFiles([...filteredArr]);
  }, [selectedFiles]);

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
  };

  const dragLeave = (e) => {
    preventDefault(e);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef?.current?.files.length) {
      handleFiles(fileInputRef?.current?.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef?.current.click();
  };

  const handleFiles = (files) => {
    // It's a Files object, it does not have an iterator, so we have to do old fashioned loop
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (validateFile(file)) {
        setSelectedFiles((prevArray) => [...prevArray, file]);
      } else {
        file["invalid"] = true;
        setSelectedFiles((prevArray) => [...prevArray, file]);
        setErrorMessage("File type not permitted");
        setUnsupportedFiles((prevArray) => [...prevArray, file]);
      }
    }
  };

  const removeFile = (name) => {
    const index = validFiles.findIndex((e) => e.name === name);
    const index2 = selectedFiles.findIndex((e) => e.name === name);
    const index3 = unsupportedFiles.findIndex((e) => e.name === name);
    validFiles.splice(index, 1);
    selectedFiles.splice(index2, 1);
    setValidFiles([...validFiles]);
    setSelectedFiles([...selectedFiles]);

    if (index3 !== -1) {
      unsupportedFiles.splice(index3, 1);
      setUnsupportedFiles([...unsupportedFiles]);
    }
  };

  return (
    <>
      <div>
        {unsupportedFiles.length ? (
          <p>{t("Please remove all unsupported files")}.</p>
        ) : (
          ""
        )}
        <div
          className={styles.dropContainer}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
        >
          <div className={styles.dropMessage}>
            <div className="upload-icon"></div>
            {t("Drag & Drop files here or click to select file(s)")}
          </div>
          <input
            id={id}
            name={name}
            ref={fileInputRef}
            className={styles.fileInput}
            type="file"
            multiple
            onChange={filesSelected}
            accept={validTypes.join(",")}
          />
        </div>
        <div className={styles.fileDisplayContainer}>
          {validFiles.map((file, i) => (
            <div className={styles.fileStatusbar} key={i}>
              <div className={styles.imageThumbnailContainer}>
                <img
                  className={styles.imageThumbnail}
                  alt={file.name}
                  src={window.URL.createObjectURL(file)}
                />
              </div>
              <div className={styles.fileType}>{fileType(file.name)}</div>
              <span
                className={`${styles.fileName} ${
                  file.invalid ? styles.fileError : ""
                }`}
              >
                {file.name}
              </span>
              <span className={styles.fileSize}>({fileSize(file.size)})</span>{" "}
              {file.invalid && (
                <span className={styles.fileErrorMessage}>
                  ({errorMessage})
                </span>
              )}
              <div
                className={styles.fileRemove}
                onClick={() => removeFile(file.name)}
              >
                X
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

const fileSize = (size) => {
  if (size === 0) {
    return "0 Bytes";
  }
  const k = 1024;

  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

const fileType = (fileName) => {
  return (
    fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
    fileName
  );
};

const preventDefault = (e) => {
  e.preventDefault();
};

const validTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/x-icon",
];

const validateFile = (file) => {
  if (validTypes.indexOf(file.type) === -1) {
    return false;
  }

  return true;
};

export default ImageUpload;
