import { TableHead } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";
import { useTranslation } from "react-i18next";

export default function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    { id: "first_name", left: true, disablePadding: false, label: t("Name") },
    {
      id: "account_email",
      left: false,
      disablePadding: false,
      label: t("Email"),
    },
    {
      id: "account_creation_date",
      left: false,
      disablePadding: false,
      label: t("Created at"),
    },
    {
      id: "profession",
      left: false,
      disablePadding: false,
      label: t("Profession"),
    },
    {
      id: "specialty",
      left: false,
      disablePadding: false,
      label: t("Specialty"),
    },
    {
      id: "company",
      left: false,
      disablePadding: false,
      label: t("Company"),
    },
    {
      id: "account_is_active",
      left: false,
      disablePadding: false,
      label: t("Active"),
    },
    {
      id: "delete_col",
      left: false,
      disablePadding: false,
      label: "",
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.left ? "left" : "right"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
