import React, { Suspense, useEffect, useRef } from "react";
import { Layout, Paragraph, Section, Title } from "ui/";
import Skeleton from "ui/Skeleton/Skeleton";
import EmbeddedEstimateForm from "components/Forms/EmbeddedEstimateForm/EmbeddedEstimateForm";
const Chart = React.lazy(() => import("components/Chart/Chart"));

const EmbeddedEstimatePage = () => {
  const [chartData, setChartData] = React.useState();
  const [isLoadingScenarios, setIsLoadingScenarios] = React.useState(false);
  const [chartName, setChartName] = React.useState();
  const chartRef = useRef(null);

  const onFormSubmit = (data, chartname) => {
    setChartName(chartname);
    setChartData(data);
  };

  useEffect(() => {
    if (chartData) {
      setIsLoadingScenarios(true);
      chartRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [chartData, isLoadingScenarios]);
  return (
    <>
      <Section>
        <Layout position="topCenter">
          <Title>PATHFx 3.0</Title>
          <Paragraph color="purple">
            PATHFx prognostiserar överlevnad för cancerpatienter med
            symptomgivande skelettmetastas i rygg eller extremitet.
          </Paragraph>
          <Paragraph color="purple">
            För ytterligare information: www.pathfx.org
          </Paragraph>
          <Paragraph color="purple">
            Svara på frågorna nedan. Om du inte har tillgång till en viss
            uppgift så lämna fältet tomt. Beslutsstödet fungerar även om viss
            data saknas, om än med sämre precision.
          </Paragraph>
          <EmbeddedEstimateForm onSuccessSubmit={onFormSubmit} />
        </Layout>
      </Section>
      {chartData && (
        <div ref={chartRef}>
          <Section bgColor="lightBlue">
            <Layout position="topCenter">
              <Suspense fallback={<Skeleton />}>
                <Chart chartData={chartData} chartName={chartName} />
              </Suspense>
            </Layout>
          </Section>
        </div>
      )}
    </>
  );
};

export default EmbeddedEstimatePage;
