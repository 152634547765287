import React from "react";
import styles from "./callout.module.scss";
import PropTypes from "prop-types";

const Callout = ({ children, href }) => {
  return (
    <div className={styles.callout}>
      <a href={href}>{children}</a>
    </div>
  );
};

Callout.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

export default Callout;
