import React from "react";
import { Heading, Button, Image, Container } from "ui/";
import styles from "./region.module.scss";
import { useTranslation } from "react-i18next";

const Region = ({ region, onRegionSelect }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.region}>
      <Container position="center">
        <Image
          size="small"
          imgSrc={"https:" + region.photo.fields.file.url}
        ></Image>
        <Heading>{region.name}</Heading>
        <Button
          type="button"
          outlined
          onClick={() => onRegionSelect(region.name)}
        >
          {t("Select this region")}
        </Button>
      </Container>
    </div>
  );
};

export default Region;
