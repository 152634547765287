import React from "react";
import styles from "./rangeSlider.module.scss";
import Slider from "@material-ui/core/Slider";
import { withStyles } from "@material-ui/core/styles";
import { InputText } from "ui/Form/";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  const { t } = useTranslation();
  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={`${value} ${t("month(s)")}`}
    >
      <span>{children}</span>
    </Tooltip>
  );
}

const AirbnbSlider = withStyles({
  root: {
    color: "#91cfc8",
    height: 3,
    padding: "2rem 0",
    textAlign: "center",
  },
  thumb: {
    height: 27,
    width: 17,
    backgroundColor: "#fff",
    border: "1px solid #3FB8AF",
    borderRadius: "3px",
    boxShadow: "#ebebeb 0 2px 2px",
    "&:focus, &:hover, &$active": {
      boxShadow: "#ccc 0 2px 3px 1px",
    },
    "& .bar": {
      height: 9,
      width: 1,
      backgroundColor: "#3FB8AF",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 20,
    borderRadius: "3px",
    color: "#91cfc8",
    border: "1px #ebebeb solid",
  },
  rail: {
    borderRadius: "3px",
    color: "#f8f8fe",
    opacity: 1,
    height: 20,
    border: "1px #ebebeb solid",
  },
})(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}
const formatRangeDataToMonths = (value, t) => {
  return `${value[0]} ${t("month(s)")} - ${value[1]} ${t("month(s)")}`;
};

const RangeSlider = ({ name }) => {
  const [value, setValue] = React.useState([5, 13]);
  const { t } = useTranslation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const minMonth = 1;
  const maxMonth = 24;

  return (
    <div className={styles.rangeSlider} id="survivalRange">
      <AirbnbSlider
        min={minMonth}
        max={maxMonth}
        value={value}
        name={name}
        onChange={handleChange}
        ThumbComponent={AirbnbThumbComponent}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        getAriaValueText={(value) => {
          return formatRangeDataToMonths(value, t);
        }}
        ValueLabelComponent={ValueLabelComponent}
      />
      <InputText>{formatRangeDataToMonths(value, t)}</InputText>
    </div>
  );
};
export default RangeSlider;
