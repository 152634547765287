import React from "react";
import SectionTitleAndParagraph from "./SectionTitleAndParagraph/SectionTitleAndParagraph";
import SectionTitleParagraphAndButton from "./SectionTitleParagraphAndButton/SectionTitleParagraphAndButton";
import SectionTitleParagraphImageButton from "./SectionTitleParagraphImageButton/SectionTitleParagraphImageButton";
import SectionTitleParagraphListAndImage from "./SectionTitleParagraphListAndImage/SectionTitleParagraphListAndImage";
import SectionTwoTitlesTwoImagesTwoParagraphs from "./SectionTwoTitlesTwoImagesTwoParagraphs/SectionTwoTitlesTwoImagesTwoParagraphs";
import SectionTitleParagraphAndImage from "./SectionTitleParagraphAndImage/SectionTitleParagraphAndImage";
import SectionTitleParagraphAndValidationList from "./SectionTitleParagraphAndValidationList/SectionTitleParagraphAndValidationList";
const DynamicSection = ({ section, idx, narrow = true }) => {
  switch (section?.sys.contentType.sys.id) {
    case "twoTitlesTwoImagesTwoParagraphs":
      return (
        <SectionTwoTitlesTwoImagesTwoParagraphs
          section={section?.fields}
          bgColor={GetColor(idx)}
        />
      );
    case "titleParagraphAndValidationList":
      return (
        <SectionTitleParagraphAndValidationList
          section={section?.fields}
          bgColor={GetColor(idx)}
        />
      );
    case "titleParagraphListAndImage":
      return <SectionTitleParagraphListAndImage section={section?.fields} />;
    case "titleParagraphImageButton":
      return (
        <SectionTitleParagraphImageButton
          section={section?.fields}
          position={GetPosition(idx)}
          bgColor={GetColor(idx)}
        />
      );
    case "titleParagraphAndButton":
      return (
        <SectionTitleParagraphAndButton
          section={section?.fields}
          bgColor={GetColor(idx)}
        />
      );
    case "titleParagraphAndImage":
      return (
        <SectionTitleParagraphAndImage
          section={section?.fields}
          bgColor={GetColor(idx)}
        />
      );
    case "titleAndParagraph":
    default:
      return (
        <SectionTitleAndParagraph
          section={section?.fields}
          narrow={narrow}
          bgColor={GetColor(idx)}
        />
      );
  }
};

export default DynamicSection;
const colors = ["white", "lightBlue"];
const GetColor = (idx) => {
  return colors[idx % 2];
};
const GetPosition = (idx) => {
  return idx % 2 === 0 ? "right" : "left";
};
