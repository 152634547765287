import { Button } from "@material-ui/core";
import { Layout, Section, Title } from "ui";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import useApi from "auth/useApi";
import TablePaginationActions from "../../components/TablePaginationActions/TablePaginationActions";
import ConfirmDeleteUserDialog from "../../components/ConfirmDeleteUserDialog/ConfirmDeleteUserDialog";
import EnhancedTableHead from "components/EnhancedTableHead/EnhancedTableHead";
import { CSVLink } from "react-csv";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function UsersPage() {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("account_creation_date");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [users, setUsers] = useState([]);
  const [userToBeDeleted, setUserToBeDeleted] = useState(null);
  const { t } = useTranslation();
  const apiClient = useApi();

  useEffect(() => {
    apiClient.GetUsers().then((data) => {
      setUsers(data.users);
    });
  }, []);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const handleRequestSort = (event, headCell) => {
    const isAsc = orderBy === headCell && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(headCell);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const closeConfirmDeleteUserDialog = () => setUserToBeDeleted(null);

  const deleteUser = () => {
    const username = userToBeDeleted;
    closeConfirmDeleteUserDialog();
    apiClient.DeleteUser(userToBeDeleted).then(() => {
      setUsers((prev) => prev.filter((u) => u.username !== username));
    });
  };

  const headers = [
    { label: "First Name", key: "first_name" },
    { label: "Last Name", key: "last_name" },
    { label: "Email", key: "account_email" },
  ];

  return (
    <Section>
      <Layout position="topCenter">
        <Title>{t("Users")}</Title>
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            aria-label="custom pagination table"
            size="small"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(users, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user, index) => {
                  const labelId = `enhanced-table-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={user.username}>
                      <TableCell component="th" scope="row" id={labelId}>
                        {user.first_name} {user.last_name}
                      </TableCell>
                      <TableCell align="right">{user.account_email}</TableCell>
                      <TableCell align="right">
                        {user.account_creation_date}
                      </TableCell>
                      <TableCell align="right">{user.profession}</TableCell>
                      <TableCell align="right">{user.specialty}</TableCell>
                      <TableCell align="right">{user.company}</TableCell>
                      <TableCell align="right">
                        {user.account_is_active ? t("Yes") : t("No")}
                      </TableCell>
                      <TableCell>
                        <Button
                          size="small"
                          variant="contained"
                          color="secondary"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            setUserToBeDeleted(user.username);
                          }}
                        >
                          {t("Delete")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage={t("Rows per page")}
                  rowsPerPageOptions={[
                    10,
                    25,
                    50,
                    { label: t("All"), value: -1 },
                  ]}
                  colSpan={8}
                  count={users.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": t("Rows per page") },
                    native: true,
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
        <br />
        <div>
          <CSVLink data={users} headers={headers}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#6e83dd", color: "#fff" }}
              startIcon={<DownloadIcon />}
            >
              {t("Download email adresses (CSV)")}
            </Button>
          </CSVLink>
        </div>
      </Layout>
      <ConfirmDeleteUserDialog
        open={!!userToBeDeleted}
        handleClose={closeConfirmDeleteUserDialog}
        handleConfirm={deleteUser}
      />
    </Section>
  );
}
