import React from "react";
import styles from "./imageContainer.module.scss";
import PropTypes from "prop-types";

const ImageContainer = ({ imgSrc, altTxt, href }) => {
  return (
    <div className={styles.imageContainer}>
      <a href={href} target="_blank" rel="noreferrer">
        <img className={styles.image} src={imgSrc} alt={altTxt && altTxt} />
      </a>
    </div>
  );
};

ImageContainer.propTypes = {
  altTxt: PropTypes.string,
};

export default ImageContainer;
