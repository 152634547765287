import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Heading } from "ui/";
import ScenarioItem from "./ScenarioItem";

const ScenarioDialog = ({
  dialogOpen,
  handleCloseDialog,
  currentScenarios,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Fade}
      onClose={handleCloseDialog}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Heading color="darkPurple">{t("Scenario")}</Heading>
      </DialogTitle>
      <DialogContent>
        {currentScenarios.map((scenario) => (
          <ScenarioItem key={scenario.sys.id} scenario={scenario.fields} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button type="button" onClick={handleCloseDialog}>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioDialog;
