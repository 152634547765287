import "formdata-polyfill";
import React, { useRef, useState } from "react";
import styles from "./form.module.scss";
import { useTranslation } from "react-i18next";
import { sitekey } from "page-components/recaptcha/recaptcha";
import { Button, Fieldset } from "ui/";
import ApiClient from "auth/client/ApiClient";
import { InputGroup, Input } from "ui/Form";
import Textarea from "ui/Form/Textarea";
import { getPrivacyCookie } from "page-components/Privacy/Consent";

const ContactForm = () => {
  const { t } = useTranslation();
  const form = useRef(null);
  const privacyConsent = getPrivacyCookie() !== undefined;
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const onSubmitContactForm = (_) => {
    form.current.submit();
  };

  if (!window["onSubmitContactForm"]) {
    window["onSubmitContactForm"] = onSubmitContactForm;
  }

  const onSubmitHandler = async (event) => {
    const formData = new FormData(event.target);
    const body = {};

    formData.forEach((value, key) => {
      body[key] = value;
    });
    event.preventDefault();
    setSubmitting(true);

    await new ApiClient(null).Contact(body).then(() => {
      setSubmitting(false);
      setMessage(t("Message sent"));
    });
  };
  const formDisabled = !privacyConsent || submitting;
  return (
    // @ts-ignore
    <form className={styles.form} ref={form} onSubmit={onSubmitHandler}>
      {!privacyConsent && (
        <p>
          {t("This form is protected against robots")}.
          {t(
            "If you wish to use it, you will have to accept the cookie consent"
          )}
          .
        </p>
      )}
      <Fieldset disabled={formDisabled}>
        {message && <p>{message}</p>}
        <InputGroup>
          <Input
            id="nameInput"
            name="name"
            placeholder={t("Name")}
            disabled={formDisabled}
          />
        </InputGroup>

        <InputGroup>
          <Input
            type="email"
            name="email"
            id="emailInput"
            placeholder={t("Email")}
            disabled={formDisabled}
          />
        </InputGroup>
        <InputGroup>
          <Textarea
            type="textarea"
            name="message"
            id="message"
            rows={4}
            placeholder={t("Message")}
            disabled={formDisabled}
          />
        </InputGroup>
        <Button
          type="submit"
          className="g-recaptcha"
          data-sitekey={sitekey}
          data-callback="onSubmitContactForm"
          data-action="submit"
        >
          {t("Submit")}
        </Button>
      </Fieldset>
    </form>
  );
};

export default ContactForm;
