import { DynamicSection } from "page-components/DynamicSection";
import { BasePageSection } from "pages/BasePage";
import React from "react";

const About = ({ page }) => {
  const sections = page ? page.sections : Array(3);

  return (
    <>
      <BasePageSection page={page} />
      {sections.map((section, idx) => {
        return (
          <DynamicSection key={idx} section={section} idx={idx} narrow={true} />
        );
      })}
    </>
  );
};

export default About;
