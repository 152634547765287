import React, { Suspense, useContext, useEffect, useRef } from "react";
import { Layout, Paragraph, Section, Title } from "ui/";
import { useTranslation } from "react-i18next";
import Skeleton from "ui/Skeleton/Skeleton";
import Tabs from "ui/Tabs/Tabs";
import EstimateForm from "components/Forms/EstimateForm/EstimateForm";
import ProstateForm from "components/Forms/ProstateForm/ProstateForm";
import Scenarios from "components/Scenario/Scenarios";
import { LanguageContext } from "LanguageProvider";
import useContentfulClient from "hook/Contentful/useContentfulClient";
const Chart = React.lazy(() => import("components/Chart/Chart"));

const EstimatePage = () => {
  const [chartData, setChartData] = React.useState();
  const [predicted, setPredicted] = React.useState(null);
  const [isLoadingScenarios, setIsLoadingScenarios] = React.useState(false);
  const [isLoadingRegions, setIsLoadingRegions] = React.useState(false);
  const [scenarios, setScenarios] = React.useState([]);
  const [chartName, setChartName] = React.useState();
  const [regions, setRegions] = React.useState(null);
  const { userLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();
  const client = useContentfulClient();
  const chartRef = useRef(null);

  const onFormSubmit = (data, chartname) => {
    setPredicted(GetPredicted(data));
    setChartName(chartname);
    setChartData(data);
  };

  useEffect(() => {
    if (isLoadingRegions && regions === null) {
      setRegions([]);

      client
        .getEntries({ content_type: "region", locale: userLanguage })
        .then((response) => {
          setRegions(response.items);
          setIsLoadingRegions(false);
        });
    }

    if (!regions) {
      setIsLoadingRegions(true);
    }
  }, [isLoadingRegions, regions]);

  useEffect(() => {
    if (chartData && isLoadingScenarios) {
      client
        .getEntries({ content_type: "scenario", locale: userLanguage })
        .then((response) => {
          setScenarios(response.items);
        });
    }
    if (chartData) {
      setIsLoadingScenarios(true);
      chartRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [chartData, isLoadingScenarios]);

  const tabs = [
    {
      title: t("Other primary tumors"),
      content: <EstimateForm onSuccessSubmit={onFormSubmit} />,
    },
    {
      title: t("Prostate"),
      content: <ProstateForm onSuccessSubmit={onFormSubmit} />,
    },
  ];

  return (
    <>
      <Section>
        <Layout position="topCenter">
          <Title>{t("Model Inputs")}</Title>
          <Paragraph color="purple">
            {t(
              "Fill in the appropriate data below. If you do not know a value, leave it blank or select “Unknown.” The model can function with missing data."
            )}
            .
          </Paragraph>
          <Tabs tabs={tabs} />
        </Layout>
      </Section>
      {chartData && (
        <div ref={chartRef}>
          <Section bgColor="lightBlue">
            <Layout position="topCenter">
              <Suspense fallback={<Skeleton />}>
                <Chart chartData={chartData} chartName={chartName} />
              </Suspense>
            </Layout>
          </Section>
        </div>
      )}
      {(isLoadingScenarios || scenarios) && regions && chartData && (
        <Scenarios
          scenarios={scenarios}
          regions={regions}
          predicted={predicted}
        />
      )}
    </>
  );
};

export default EstimatePage;

function GetPredicted(data) {
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      if (element.patient.predicted === "yes") {
        return { expected: Number(key), data: element };
      }
    }
  }

  return null;
}
