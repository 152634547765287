import React from "react";
import styles from "./input.module.scss";

const Label = ({ htmlFor, ...props }) => {
  const { className, ...otherProps } = props;
  const mergeClassName =
    styles.label + (className !== undefined ? " " + className : "");

  return <label htmlFor={htmlFor} className={mergeClassName} {...otherProps} />;
};

export default Label;
