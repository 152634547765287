import React from "react";
import styles from "./input.module.scss";

const Input = (props) => {
  const { className, ...otherProps } = props;
  const mergeClassName = styles.input + (className ? " " + className : "");
  return <input className={mergeClassName} {...otherProps} />;
};

export default Input;
