import React from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
// import Button from "@material-ui/core/Button";

const AlertInfo = () => {
  const [open, setOpen] = React.useState(true);
  return (
    <div>
      <Collapse in={open}>
        <Alert
          severity="info"
          onClose={() => {
            setOpen(false);
          }}
        >
          <AlertTitle>Prostate Info</AlertTitle>
          PATHFx provides 1, 3, 6, 12, 18, and 24 month survival estimates —{" "}
          <strong>
            Longer estimates are possible using the Prostate Disease-Specific
            Tab.
          </strong>
        </Alert>
      </Collapse>
    </div>
  );
};

export default AlertInfo;
