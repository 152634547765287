import React from "react";
import styles from "./Skeleton.module.scss";
import PropTypes from "prop-types";

const Skeleton = (props) => {
  const { count = 1, circle, height, width, minHeight } = props;
  const elements = [];

  for (let i = 0; i < count; i++) {
    const style = { height: height, width: width, minHeight: minHeight };

    if (circle) {
      style.borderRadius = "50%";
      if (!width && !height) {
        style.width = "1em";
        style.height = "1em";
      }
    }
    if (props.block) {
      style.display = "block";
    }
    elements.push(
      <span key={i} className={styles.skeleton} style={style}>
        &zwnj;
      </span>
    );
  }

  return <>{elements}</>;
};

Skeleton.propTypes = {
  count: PropTypes.number,
  circle: PropTypes.bool,
  height: PropTypes.string,
  minHeight: PropTypes.string,
  width: PropTypes.string,
  block: PropTypes.bool,
};

export default Skeleton;
