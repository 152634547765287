import React from "react";
import styles from "./quickLinksItem.module.scss";
import { NavLink } from "react-router-dom";

const QuickLinksItem = ({ children, path }) => {
  return (
    <li className={styles.quickLinksItem}>
      <NavLink to={path} activeClassName={styles.activeFooterLink}>
        {children}
      </NavLink>
    </li>
  );
};

export default QuickLinksItem;
