import React from "react";
import {
  Layout,
  Heading,
  Skeleton,
  Paragraph,
  Container,
  ImageContainer,
  List,
  SectionWithBackground,
} from "ui/";

import { ListItem } from "ui/List/List";

const SectionTitleParagraphListAndImage = ({ section }) => {
  return (
    <SectionWithBackground
      bgUrl={
        section
          ? "https:" + section.backgroundImage?.fields?.file?.url
          : undefined
      }
    >
      <Layout position="left">
        <Container>
          <Heading color="white">
            {section ? section.title : <Skeleton />}
          </Heading>
          <Paragraph color="white">
            {section ? section.paragraph : <Skeleton />}
          </Paragraph>
          <Heading color="white">
            {section ? section.listTitle : <Skeleton />}
          </Heading>
          <List>
            {section ? (
              section.list.map((l) => <ListItem key={l}>{l}</ListItem>)
            ) : (
              <Skeleton />
            )}
          </List>
        </Container>
        <Container>
          <ImageContainer
            imgSrc={
              section ? "https:" + section.image?.fields?.file?.url : undefined
            }
            altTxt={section ? section.image?.fields?.title : "Loading"}
            href="https://karolinskainnovations.ki.se/en/"
          />
        </Container>
      </Layout>
    </SectionWithBackground>
  );
};

export default SectionTitleParagraphListAndImage;
