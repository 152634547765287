import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { GetButtonClassNames } from "./Button";

const PathButton = ({ children, path, color = "green", size = undefined }) => {
  return (
    <>
      <NavLink className={GetButtonClassNames(color, size)} to={path}>
        {children}
      </NavLink>
    </>
  );
};

PathButton.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  color: PropTypes.oneOf(["green", "blue", "darkgreen"]),
  size: PropTypes.oneOf(["small"]) || undefined,
};

export default PathButton;
