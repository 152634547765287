import React, { useEffect } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
const Popup = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(false);
  };

  const handleDontShowAgain = () => {
    localStorage.setItem("pop_status", "true");
    setOpen(false);
  };

  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status || pop_status === "false") {
      setOpen(true);
    }
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Prostate"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            These models are intended only for use in men with skeletal related
            events due to metastatic prostate cancer
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOpen} color="primary">
            Acknowledge
          </Button>
          <Button onClick={handleDontShowAgain} color="primary">
            Dont show again
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Popup;
