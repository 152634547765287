import React from "react";
import styles from "./menu.module.scss";

const Menu = ({ children }) => {
  return (
    <nav className={styles.menu}>
      <ul>{children}</ul>
    </nav>
  );
};

export default Menu;
