import React, { useContext, useEffect, useState } from "react";
import styles from "../Header/header.module.scss";
import Menu from "./Menu/Menu";
import MenuItem from "./MenuItem/MenuItem";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu/MobileMenu";
import MobileMenuItem from "./MobileMenu/MobileMenuItem/MobileMenuItem";
import { useTranslation } from "react-i18next";
import Burger from "./Burger/Burger";
import LanguageSelector from "./LanguageSelector";
import { AuthContext } from "auth/AuthProvider";
import Logo from "page-components/Logo/Logo";
import CELogo from "assets/images/ce_logo.svg";

const Header = ({ homeState }) => {
  homeState = homeState === true;
  const { t } = useTranslation();
  const { token, userData } = useContext(AuthContext);
  const [navbar, setNavbar] = useState(homeState);
  const [navHeight, setNavHeight] = useState(homeState);
  const [menu, setMenu] = useState(false);

  const menuHandler = () => {
    setMenu(!menu);
  };

  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpointBurger = 769;
  const HeaderClassNames = classNames(styles.header, {
    [styles.cover]: menu === true,
  });
  const BackgroundClassNames = classNames(styles.background, {
    [styles.transp]: navbar === true,
    [styles.cover]: menu === true,
  });
  const LayoutClassNames = classNames(styles.layout, {
    [styles.active]: navHeight === false,
  });

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(false);
    } else {
      setNavbar(true);
    }
  };

  const changeHeight = () => {
    if (window.scrollY >= 33) {
      setNavHeight(false);
    } else {
      setNavHeight(true);
    }
  };

  useEffect(() => {
    setNavbar(homeState);
    setNavHeight(homeState);
    if (homeState) {
      window.addEventListener("scroll", changeBackground);
      window.addEventListener("scroll", changeHeight);
    }
    return () => {
      window.removeEventListener("scroll", changeBackground);
      window.removeEventListener("scroll", changeHeight);
    };
  }, [homeState]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [width]);

  return (
    <header className={HeaderClassNames}>
      <div className={BackgroundClassNames}> </div>
      <div className={LayoutClassNames}>
        <Link to="/" className={styles.logoLink}>
          <div className={styles.logoContainer}>
            <Logo />
            <img src={CELogo} alt="Ce Logo" height="12px"></img>
          </div>
        </Link>
        {width < breakpointBurger ? (
          <>
            <Burger menuHandler={menuHandler} />
            <MobileMenu menu={menu}>
              {token && userData?.account_type === "Admin" && (
                <MobileMenuItem path="/users">{t("Users")}</MobileMenuItem>
              )}
              <MobileMenuItem path="/estimate-survival">
                {t("Estimate Survival")}
              </MobileMenuItem>
              <MobileMenuItem path="/about">{t("About")}</MobileMenuItem>
              <MobileMenuItem path="/how-to-use">
                {t("How to Use")}
              </MobileMenuItem>
              <MobileMenuItem path="/how-it-works">
                {t("How it Works")}
              </MobileMenuItem>
              <MobileMenuItem path="/share-a-case">
                {t("Share a Case")}
              </MobileMenuItem>
              {token ? (
                <MobileMenuItem path="/logout">{t("Logout")}</MobileMenuItem>
              ) : (
                <>
                  <MobileMenuItem path="/login">{t("Login")}</MobileMenuItem>
                  <MobileMenuItem path="/register">
                    {t("Register")}
                  </MobileMenuItem>
                </>
              )}
            </MobileMenu>
          </>
        ) : (
          <Menu>
            {token && userData?.account_type === "Admin" && (
              <MenuItem itemStyle="fill" itemText={t("Users")} path="/users" />
            )}
            <MenuItem
              itemStyle="fill"
              itemText={t("Estimate Survival")}
              path="/estimate-survival"
            />
            <MenuItem path="/about" itemText={t("About")} />
            <MenuItem path="/how-to-use" itemText={t("How to Use")} />
            <MenuItem path="/how-it-works" itemText={t("How it Works")} />
            <MenuItem path="/share-a-case" itemText={t("Share a Case")} />
            {token ? (
              <MenuItem
                path="/logout"
                itemStyle="outlined"
                itemText={t("Logout")}
              />
            ) : (
              <>
                <MenuItem
                  path="/login"
                  itemStyle="fill"
                  itemText={t("Login")}
                />
                <MenuItem
                  path="/register"
                  itemStyle="outlined"
                  itemText={t("Register")}
                />
              </>
            )}

            <LanguageSelector />
          </Menu>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  homeState: PropTypes.bool,
};

export default Header;
