import RegisterForm from "components/Forms/RegisterForm/RegisterForm";
import React from "react";
import { useTranslation } from "react-i18next";
import { Section, Layout, Title, Heading } from "ui/";

const Register = () => {
  const { t } = useTranslation();
  return (
    <>
      <Section>
        <Layout position="topCenter">
          <Title>{t("Register")}</Title>
          <Heading>
            {t("Please fill out the form below and register to use PATHFx")}
          </Heading>
        </Layout>
      </Section>
      <Section bgColor="lightBlue">
        <Layout position="topCenter">
          <RegisterForm />
        </Layout>
      </Section>
    </>
  );
};

export default Register;
