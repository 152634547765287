import React from "react";
import PropTypes from "prop-types";
import { GetButtonClassNames } from "./Button";

const LinkButton = ({ children, href, color, size = undefined }) => {
  return (
    <>
      <a
        className={GetButtonClassNames(color, size)}
        href={href}
        rel="noreferrer"
        target="_blank"
      >
        {children}
      </a>
    </>
  );
};

LinkButton.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  color: PropTypes.oneOf(["green", "blue", "darkgreen"]),
  size: PropTypes.oneOf(["small"]) || undefined,
};

export default LinkButton;
