import SkeletalRegions from "components/SkeletalRegions/SkeletalRegions";
import React, { useState } from "react";
import { Layout, Section } from "ui/";
import ScenarioDialog from "./ScenarioDialog";

const Scenarios = ({ scenarios, regions, predicted }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentScenarios, setCurrentScenarios] = useState([]);

  const onRegionSelect = (name) => {
    const filteredItems = GetScenariosByEstimate(scenarios, predicted, name);

    setCurrentScenarios(filteredItems);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div>
      <Section>
        <Layout position="topCenter">
          <SkeletalRegions regions={regions} onRegionSelect={onRegionSelect} />
        </Layout>
      </Section>
      <ScenarioDialog
        dialogOpen={dialogOpen}
        handleCloseDialog={handleCloseDialog}
        currentScenarios={currentScenarios}
      />
    </div>
  );
};

export default Scenarios;

const GetScenariosByEstimate = (scenarios, predicted, name) => {
  return scenarios.filter(
    (scenario) =>
      scenario.fields.region === name &&
      Number(scenario.fields.estimated_survival.slice(0, -1)) <=
        predicted.expected
  );
};
