class TokenClient {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL;
  }

  /**
   *
   * @param {Object} body
   * @param {string} body.username "3",
   * @param {string} body.password  5,
   * @returns {Promise} - Object as response
   */
  Auth(body) {
    return fetch(this.baseUrl + "/auth", {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: body ? JSON.stringify(body) : undefined,
    }).then((response) => {
      return response.json().then((data) => {
        return data;
      });
    });
  }

  /**
   *
   * @param {Object} body
   * @param {string} body.token "3",
   * @returns {Promise} - Object as response
   */
  Token(body) {
    return fetch(this.baseUrl + "/token", {
      method: "post",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: body ? JSON.stringify(body) : undefined,
    }).then((response) => {
      return response.json().then((data) => {
        return data;
      });
    });
  }
}

export default TokenClient;
