import styles from "./hero.module.scss";
import React, { useContext } from "react";
import {
  Callout,
  Container,
  HeroTitle,
  HeroTitleSecondary,
  Layout,
  PathButton,
  SectionWithBackground,
} from "ui/";

import useContentfulClient from "hook/Contentful/useContentfulClient";
import heroBg from "../../assets/images/hero.jpeg";
import Spotlight from "./Spotlight";
import { LanguageContext } from "LanguageProvider";

const Hero = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const client = useContentfulClient();
  const { userLanguage } = useContext(LanguageContext);
  const [heroContent, setHeroContent] = React.useState([]);

  React.useEffect(() => {
    if (isLoading) {
      GetEntries(client, userLanguage).then((response) => {
        setHeroContent(response.items);
      });
    }
    setIsLoading(true);
  }, [isLoading, userLanguage]);

  const GetEntries = (client, userLanguage) => {
    return client.getEntries({
      content_type: "hero",
      locale: userLanguage,
      include: 2,
    });
  };

  return (
    <SectionWithBackground bgUrl={heroBg}>
      <Layout position="topColumn">
        <HeroTitle>{heroContent[0]?.fields?.heroTitle}</HeroTitle>
        <Container>
          <HeroTitleSecondary>
            {heroContent[0]?.fields?.heroSecondaryTitle}
          </HeroTitleSecondary>
          <HeroTitleSecondary>
            {heroContent[0]?.fields?.heroHeading}
          </HeroTitleSecondary>
        </Container>
        <Callout href={heroContent[0]?.fields.heroLinkUrl}>
          {heroContent[0]?.fields.heroLinkText}
        </Callout>
        <div className={styles.spotlightContainer}>
          <Spotlight heroContent={heroContent && heroContent[0]?.fields} />
        </div>
        <PathButton path="/estimate-survival">
          {heroContent[0]?.fields.buttonText}
        </PathButton>
      </Layout>
    </SectionWithBackground>
  );
};

export default Hero;
