import React from "react";
import styles from "./title.module.scss";

const Title = ({ children }) => {
  return (
    <div>
      <h1 className={styles.title}>{children}</h1>
    </div>
  );
};

export default Title;
