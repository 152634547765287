import { useState, useRef, useEffect } from "react";

function useCustomForm({ initialValues, onSubmitHandler }) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [changed, setChanged] = useState({});
  const [onSubmitting, setOnSubmitting] = useState(false);
  const [onBlur, setOnBlur] = useState(false);
  const isMounted = useRef(false);

  const handleChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    const oldValue = values ? values[name] : null;
    const oldValueIsNumeric =
      oldValue !== undefined &&
      oldValue !== null &&
      Number(oldValue) === oldValue;
    updateValue(name, oldValueIsNumeric ? Number(value) : value);
  };

  const updateError = (name, value) => {
    setErrors({ ...errors, [name]: value });
  };

  const updateValue = (name, value) => {
    const initialValue = initialValues ? initialValues[name] : null;
    setChanged({ ...changed, [name]: initialValue != value });
    setValues({ ...values, [name]: value });
  };

  const handleBlur = (event) => {
    const { target } = event;
    const { name } = target;

    setTouched({ ...touched, [name]: true });
  };

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    setErrors({ ...errors });
    setOnSubmitting(true);

    const d = await onSubmitHandler({ values, errors });
    isMounted.current && setOnSubmitting(false);
    return d;
  };

  useEffect(() => {
    isMounted.current = true;
    setValues(initialValues);
    setErrors({});
    setTouched({});
    setOnSubmitting(false);
    setOnBlur(false);
    return () => {
      isMounted.current = false;
    };
  }, [initialValues]);

  return {
    values,
    errors,
    touched,
    changed,
    onSubmitting,
    onBlur,
    updateValue,
    handleChange,
    handleBlur,
    handleSubmit,
    updateError,
  };
}

export default useCustomForm;
