import React from "react";
import { Heading, Image, Layout, Section, Skeleton, Paragraph } from "ui";

const SectionTitleParagraphAndImage = ({ section, bgColor }) => {
  return (
    <Section bgColor={bgColor}>
      <Layout narrow={true} position="top">
        <Image
          imgSrc={
            section ? "https:" + section.image?.fields?.file?.url : undefined
          }
          size="small"
        />
        <Heading>{section ? section.title : <Skeleton />}</Heading>
        <Paragraph color="grey">
          {section ? section.paragraph : <Skeleton />}
        </Paragraph>
      </Layout>
    </Section>
  );
};

export default SectionTitleParagraphAndImage;
