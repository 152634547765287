import React from "react";
import PropTypes from "prop-types";

import styles from "./container.module.scss";
import classNames from "classnames";

const Container = ({ children, position }) => {
  const ContainerClassNames = classNames(styles.container, {
    [styles.center]: position === "center",
  });
  return <div className={ContainerClassNames}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.node,
  position: PropTypes.oneOf(["center"]),
};
export default Container;
