import { AuthContext } from "auth/AuthProvider";
import React from "react";

const useContentfulClient = () => {
  const { contentfulClient } = React.useContext(AuthContext);
  if (!contentfulClient) {
    throw Error("Cannot connect to Contenful");
  }

  return contentfulClient;
};

export default useContentfulClient;
