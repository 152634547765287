class ApiClient {
  /**
   * @param {string} token
   */
  constructor(token) {
    this.Token = token;
    this.baseUrl = process.env.REACT_APP_API_URL;
  }
  /** @type {string} */
  Token = null;

  /**
   *
   * @param {Object} body
   * @param {string} body.dxgrp "3",
   * @param {string} body.ecog  "2.1",
   * @param {string} body.node  "yes",
   * @param {string} body.visceralmets "yes",
   * @param {string} body.bonemets "solitary",
   * @param {string} body.sex  "male",
   * @param {number} body.hb : 15,
   * @param {number} body.alc 2.5,
   * @param {Object} body.surgeonest  null,
   * @param {string} body.pathfx  "yes"
   * @returns {Promise}
   */
  PredictionExternal(body) {
    return _post(this.baseUrl + "/prediction-external", "embedded", body);
  }

  /**
   *
   * @param {Object} body
   * @param {string} body.dxgrp "3",
   * @param {string} body.ecog  "2.1",
   * @param {string} body.node  "yes",
   * @param {string} body.visceralmets "yes",
   * @param {string} body.bonemets "solitary",
   * @param {string} body.sex  "male",
   * @param {number} body.hb : 15,
   * @param {number} body.alc 2.5,
   * @param {Object} body.surgeonest  null,
   * @param {string} body.pathfx  "yes"
   * @returns {Promise}
   */
  Prediction(body) {
    return _post(this.baseUrl + "/prediction", this.Token, body);
  }
  /**
   * @param {Object} body
   * @param {number} body.proximalPSA
   * @param {number} body.metsAge
   * @param {Boolean} body.com
   * @param {Boolean} body.txNO
   * @param {Boolean} body.txRT
   * @param {Boolean} body.txHTCHEMO
   * @param {Boolean} body.apv1Quick
   * @param {Boolean} body.apv1Slow
   * @param {Boolean} body.apv1NoRising
   * @param {Boolean} body.race1CAandOT
   * @param {Boolean} body.race1AA
   * @returns {Promise}
   */
  ProstatePrediction(body) {
    return _post(this.baseUrl + "/prostate", this.Token, body);
  }

  // /**
  //  *
  //  * @param {Object} body
  //  * @param {string} body.first_name "3",
  //  * @param {string} body.last_name  "2.1",
  //  * @param {string} body.profession  "yes",
  //  * @param {string} body.specialty "yes",
  //  * @param {string} body.company "solitary",
  //  * @param {string} body.account_language  "male",
  //  * @param {number} body.username : 15,
  //  * @param {number} body.password 2.5,
  //  * @param {string} body.email  "yes"
  //  * @returns {Promise}
  //  */
  Register(body) {
    body["account_type"] = "User";
    body["username"] = body["email"];
    return _post(this.baseUrl + "/register", undefined, body);
  }

  ForgotPassword(body) {
    return _post(this.baseUrl + "/forgot-password", undefined, body);
  }
  // /**
  //  * @param {Object} body
  //  * @param {string} body.name
  //  * @param {string} body.email
  //  * @param {string} body.message
  //  *
  //  */
  Contact(body) {
    return _post(this.baseUrl + "/contact", undefined, body);
  }

  SubmitInterestingCase(body) {
    return _post(
      this.baseUrl + "/case",
      undefined,
      body,
      "multipart/form-data"
    );
  }

  /**
   * @param {Object} body
   * @param {string} body.password
   *
   */
  ChangePassword(body) {
    return _put(this.baseUrl + "/user", this.Token, body);
  }

  GetUsers() {
    return _get(this.baseUrl + "/users", this.Token);
  }

  GetUser() {
    return _get(this.baseUrl + "/user", this.Token);
  }

  DeleteUser(username) {
    return _delete(this.baseUrl + "/user/" + username, this.Token);
  }
}

/**
 * @param {RequestInfo} url
 * @param {string} token
 */
function _put(url, token, body, contentType = "application/json") {
  return _request(url, token, "put", body, contentType);
}

/**
 * @param {RequestInfo} url
 * @param {string} token
 */
function _post(url, token, body, contentType = "application/json") {
  return _request(url, token, "post", body, contentType);
}

/**
 * @param {RequestInfo} url
 * @param {string} token
 */
function _get(url, token) {
  return _request(url, token, "get", null, "application/json");
}

/**
 * @param {RequestInfo} url
 * @param {string} token
 */
function _delete(url, token) {
  return _request(url, token, "delete", null, "application/json");
}

/**
 * @param {RequestInfo} url
 * @param {string} token
 * @param {string} method
 * @param {Object} body
 * @param {string} contentType
 */
function _request(url, token, method, body, contentType) {
  const params = {
    method: method,
    headers: new Headers({
      "Content-Type": contentType,
    }),
    body: body
      ? contentType === "application/json"
        ? JSON.stringify(body)
        : body
      : undefined,
  };

  if (token) {
    params.headers.append("Authorization", "Bearer " + token);
  }

  return fetch(url, params)
    .then((response) => {
      if (response.ok) {
        return response.json().then((data) => {
          return data;
        });
      } else {
        return response.json().then((data) => {
          throw new Error(data);
        });
      }
    })
    .catch((error) => {
      throw new Error(error);
    });
}
export default ApiClient;
