import React from "react";
import { Section, Layout, Heading, Skeleton, Paragraph, PathButton } from "ui";

const SectionTitleParagraphAndButton = ({ section, bgColor }) => {
  return (
    <Section bgColor={bgColor}>
      <Layout narrow={true} position="topCenter">
        <Heading>{section ? section?.title : <Skeleton />}</Heading>
        <Paragraph>{section ? section?.paragraph : <Skeleton />}</Paragraph>
        <PathButton path="/estimate-survival" color="blue">
          {section ? section?.buttonText : <Skeleton />}
        </PathButton>
      </Layout>
    </Section>
  );
};

export default SectionTitleParagraphAndButton;
