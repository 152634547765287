import React, { useContext, useEffect } from "react";
import styles from "./embeddedEstimateForm.module.scss";
import {
  genders,
  oncologics,
  pathologics,
  skeletalMetastasesOptions,
  organMetastasesOptions,
  lymphNodeMetastasesOptions,
} from "./EmbeddedEstimateFormFieldData";
import {
  Button,
  Label,
  Fieldset,
  InputWrapper,
  NumberInput,
  RadioInput,
  SelectInput,
} from "ui/";
import useApi from "auth/useApi";
import { useTranslation } from "react-i18next";
import useCustomForm from "hook/Form/useCustomForm";
import { CircularProgress } from "@material-ui/core";
import i18n from "i18next";
import { AuthContext } from "auth/AuthProvider";

const EmbeddedEstimateForm = ({ onSuccessSubmit }) => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language.includes("en") ? "english" : "swedish";
  const { token } = useContext(AuthContext);
  const isAuthed = Boolean(token);
  const {
    values,
    changed,
    updateValue,
    handleChange,
    handleSubmit,
    handleBlur,
    onSubmitting,
  } = useCustomForm({
    initialValues,
    onSubmitHandler: async () => {
      const predictionData = isAuthed
        ? await apiClient.Prediction(compileForm(values))
        : await apiClient.PredictionExternal(compileForm(values));
      onSuccessSubmit(
        predictionData,
        t("Peka med musen mot cirklarna för mer information")
      );
    },
  });
  const apiClient = useApi(!isAuthed ? "queryToken" : undefined);
  const [disabledGenders, setDisabledGenders] = React.useState([]);
  const [disabledOncologic, setDisabledOncologic] = React.useState([]);
  const [prostateDescription, setProstateDescription] = React.useState(false);
  const saveDisabled = () => {
    return !Object.values(changed).some((v) => v);
  };

  useEffect(() => {
    if (values.gender === "female") {
      if (values.oncologic === "3.5") {
        updateValue("oncologic", oncologics[0].value);
      }

      setDisabledOncologic([
        oncologics.find((o) => o.value.toString() === "3.5"),
      ]);
    } else {
      setDisabledOncologic([]);
    }
  }, [values.gender]);

  useEffect(() => {
    if (values.oncologic === "3.5") {
      if (values.gender === "female") {
        updateValue("gender", "male");
      }
      setDisabledGenders([
        genders.find((g) => g.value.toString() === "female"),
      ]);
      setProstateDescription(true);
    } else {
      setDisabledGenders([]);
      setProstateDescription(false);
    }
  }, [values.oncologic]);

  return (
    <>
      <form className={styles.embeddedEstimateForm} onSubmit={handleSubmit}>
        <Fieldset disabled={onSubmitting}>
          <InputWrapper>
            <NumberInput
              label={t("Age at time of surgery (years)")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.age}
              min={5}
              max={120}
              name="age"
              id="age"
            />
            <SelectInput
              label={t("Gender")}
              value={values.gender}
              name="gender"
              id="gender"
              onChange={handleChange}
              onBlur={handleBlur}
              selectOptions={genders}
              disabledOptions={disabledGenders}
            />
          </InputWrapper>
          {prostateDescription}
          <InputWrapper>
            <SelectInput
              label={t("Oncologic Diagnosis")}
              value={values.oncologic}
              name="oncologic"
              id="oncologic-diagnosis"
              onChange={handleChange}
              onBlur={handleBlur}
              selectOptions={oncologics}
              description={t(
                "We grouped these diagnoses by Kaplan-Meier Survival curves. We are constructing survival models on individual diagnoses such as breast, prostate and sarcomas. Stay tuned."
              )}
              disabledOptions={disabledOncologic}
            />

            <SelectInput
              label={t("Pathologic Fracture")}
              value={values.pathologic}
              name="pathologic"
              id="pathologic-fracture"
              onChange={handleChange}
              onBlur={handleBlur}
              selectOptions={pathologics}
              description={t(
                'Distinguish between and "impending" or "actual" pathologic fracture that results in a change in length, alignment, or rotation of the bone'
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <div>
              <Label htmlFor="ecog">
                {t(
                  "Eastern Cooperative Oncology Group (ECOG) Performance Status"
                )}
              </Label>
              <RadioInput
                onChange={handleChange}
                onBlur={handleBlur}
                checked={Number(values.ecog) === 0}
                value={0}
                name="ecog"
                id="fullyActive"
                required
              >
                {t(
                  "0 - Fully active, able to carry on all pre-disease performance without restriction"
                )}
              </RadioInput>
              <RadioInput
                onChange={handleChange}
                onBlur={handleBlur}
                checked={Number(values.ecog) === 1}
                value={1}
                name="ecog"
                id="restricted"
              >
                {t(
                  "1 - Restricted in physically strenuous activity but ambulatory and able to carry out work of a light or sedentary nature, e.g., light house work or office work."
                )}
              </RadioInput>
              <RadioInput
                onChange={handleChange}
                onBlur={handleBlur}
                checked={Number(values.ecog) === 2}
                value={2}
                name="ecog"
                id="ambulatory"
              >
                {t(
                  "2 - Ambulatory and capable of all selfcare, but unable to carry out any work activities. Up and about more than 50% of waking hours."
                )}
              </RadioInput>
              <RadioInput
                onChange={handleChange}
                onBlur={handleBlur}
                checked={Number(values.ecog) === 3}
                value={3}
                name="ecog"
                id="limited-selfcare"
              >
                {t(
                  "3 - Capable of only limited selfcare, confined to a bed more than 50% of waking hours."
                )}
              </RadioInput>
              <RadioInput
                onChange={handleChange}
                onBlur={handleBlur}
                checked={Number(values.ecog) === 4}
                value={4}
                name="ecog"
                id="disabled"
              >
                {t(
                  "4 - Completely disabled. Cannot carry on any selfcare; totally confined to bed or chair."
                )}
              </RadioInput>
            </div>
          </InputWrapper>
          <InputWrapper>
            <NumberInput
              label={t("Hemoglobin Concentration")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.hemoglobin}
              min={currentLanguage === "english" ? 3 : 30}
              max={currentLanguage === "english" ? 18 : 180}
              name="hemoglobin"
              id="hemoglobin"
              step={currentLanguage === "english" ? "0.1" : "1"}
              description={t(
                "Preoperative hemoglobin concentration prior to transfusion, if applicable."
              )}
              inputFormat={currentLanguage === "english" ? "g/Dl" : "g/L"}
            />
            <NumberInput
              label={t("Absolute Lymphocyte Count")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lymphocyte}
              min={0}
              max={8}
              name="lymphocyte"
              id="lymphocyte"
              step="0.1"
              description={t("Preoperative absolute lymphocyte count")}
              isOptional
              inputFormat="K/uL"
            />
          </InputWrapper>
          <InputWrapper>
            <SelectInput
              label={t("Number of Skeletal Metastases")}
              value={values.skeletalMetastases}
              name="skeletalMetastases"
              id="skeletal-metastases"
              onChange={handleChange}
              onBlur={handleBlur}
              selectOptions={skeletalMetastasesOptions}
              description={t(
                "This includes biopsy-proven or clinically obvious disease, and can be determined on bone scan, skeletal survey or cross-sectional imaging"
              )}
            />
            <SelectInput
              label={t("Organ Metastases")}
              value={values.organMetastases}
              name="organMetastases"
              id="organ-metastases"
              onChange={handleChange}
              onBlur={handleBlur}
              selectOptions={organMetastasesOptions}
              description={t(
                "This includes biopsy proven or clinically obvious visceral metastases such as liver, lung, adrenal...etc., or brain metastases."
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <SelectInput
              label={t("Lymph Node Metastases")}
              value={values.lymphNodeMetastases}
              name="lymphNodeMetastases"
              id="lymph-node-metastases"
              onChange={handleChange}
              onBlur={handleBlur}
              selectOptions={lymphNodeMetastasesOptions}
              description={t(
                "Biopsy proven or clinically obvious lymph node metastases"
              )}
              isOptional
              canBeUndefined
            />

            <NumberInput
              label={t("Physician's Estimate of Survival")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.physiciansEstimate}
              min={1}
              max={36}
              name="physiciansEstimate"
              id="physicians-estimate"
              step="1"
              description={t(
                "Senior physician's estimate of survival, postoperatively, in months. This assessment requires some degree of expertise with this population. Enter a number from 1 to 36. If you are unsure or want to avoid bias, please leave this field blank."
              )}
              isOptional
            />
          </InputWrapper>
          <Button type="submit" size="big" disabled={saveDisabled()}>
            {t("Calculate Survival Trajectory")}
          </Button>
          {onSubmitting && (
            <div className={styles.loadingContainer}>
              <CircularProgress size={68} />
            </div>
          )}
        </Fieldset>
      </form>
    </>
  );
};

const initialValues = {
  age: 50,
  gender: "male",
  oncologic: "2.1",
  pathologic: "yes",
  hemoglobin: i18n.language.includes("en") ? 10 : 100,
  lymphocyte: undefined,
  skeletalMetastases: "solitary",
  organMetastases: "yes",
  lymphNodeMetastases: "",
  physiciansEstimate: undefined,
  ecog: 0,
};

const compileForm = (values) => {
  return {
    dxgrp: values.oncologic[0], // Only the first char seems to matter
    ecog: Number(values.ecog) <= 2 ? "<=2" : ">=3",
    node: values.lymphNodeMetastases
      ? values.lymphNodeMetastases.toLowerCase()
      : null,
    visceralmets: values.organMetastases.toLowerCase(),
    sex: values.gender.toLowerCase(),
    hb: i18n.language.includes("en")
      ? Number(values.hemoglobin)
      : Math.floor(Number(values.hemoglobin) / 10),
    alc: Number(values.lymphocyte) ? Number(values.lymphocyte) : null,
    pathfx: values.pathologic.toLowerCase(),
    surgeonest: Number(values.physiciansEstimate)
      ? Number(values.physiciansEstimate)
      : null,
    bonemets: values.skeletalMetastases.toLowerCase(),
  };
};

export default EmbeddedEstimateForm;
