import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";

function ConfirmDeleteUserDialog({ open, handleClose, handleConfirm }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{t("Delete user")}?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("This action is irreversible")}.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t("Cancel")}
        </Button>
        <Button onClick={handleConfirm} color="secondary">
          {t("Delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteUserDialog;
