import React, { useEffect } from "react";
import styles from "./prostateForm.module.scss";
import {
  Label,
  Fieldset,
  InputWrapper,
  NumberInput,
  SelectInput,
  InputGroup,
  Radio,
} from "ui/Form";
import useApi from "auth/useApi";
import { useTranslation } from "react-i18next";
import { Button, Heading, Paragraph } from "ui/";
import useCustomForm from "hook/Form/useCustomForm";
import { CircularProgress } from "@material-ui/core";
import Popup from "./Popup";
import DateInput, { format } from "ui/Form/DateInput/DateInput";

function CalculateAPV(values) {
  const alkphosdate1 = values.alkphosdate1?.getTime();
  const alkphosdate2 = values.alkphosdate2?.getTime();

  const valueIsUndefined =
    values.alkphosdate1 === undefined ||
    values.alkphosdate2 === undefined ||
    values.alkphosdate1 === null ||
    values.alkphosdate2 === null ||
    values.alkphos1 === undefined ||
    values.alkphos2 === undefined;

  if (
    values.alkphos1 > values.alkphos2 ||
    alkphosdate1 >= alkphosdate2 ||
    valueIsUndefined
  ) {
    return undefined;
  }

  const apv =
    (values.alkphos2 - values.alkphos1) /
    ((alkphosdate2 - alkphosdate1) / (1000 * 3600 * 24));

  if (apv >= 50) {
    return "apv1Quick";
  }
  if (apv <= 0) {
    return "apv1NoRising";
  }
  return "apv1Slow";
}

const ProstateForm = ({ onSuccessSubmit }) => {
  const { t } = useTranslation();
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    onSubmitting,
    updateValue,
  } = useCustomForm({
    initialValues,
    onSubmitHandler: async () => {
      const predictionData = await apiClient.ProstatePrediction(
        compileForm(values)
      );
      onSuccessSubmit(predictionData, t("Prostate"));
    },
  });
  const apiClient = useApi();

  const updateValueCallback = (name, val) => {
    updateValue(name, val);
  };

  useEffect(() => {
    updateValue("apv", CalculateAPV(values));
  }, [
    values.alkphos1,
    values.alkphos2,
    values.alkphosdate1,
    values.alkphosdate2,
  ]);

  const apvLabel =
    apvs.find((e) => e.value === values.apv)?.label ||
    "Unable to calculate APV";

  return (
    <>
      <form className={styles.prostateForm} onSubmit={handleSubmit}>
        <Popup />
        <div className={styles.headingContainer}>
          <Heading>
            {t(
              "For shorter estimates (1-24 months), choose Prostate in the tab 'Other primary tumors'"
            )}
          </Heading>
        </div>
        <Fieldset disabled={onSubmitting}>
          <InputWrapper>
            <NumberInput
              label={t("Proximal Prostate-Specific Antigen (PSA)")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.proximalPSA}
              name="proximalPSA"
              id="proximalPSA"
              step="0.1"
              description={t(
                "Last PSA drawn before being diagnosed with skeletal related event. It was assigned as the closest PSA to time of bone metastasis detection, within 3-18 months"
              )}
              inputFormat="ng/mL"
            />
            <NumberInput
              label={t("Patient age at Metastasis")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.metsAge}
              min={5}
              max={120}
              name="metsAge"
              id="metsAge"
              description={t(
                "Age at first know prostate skeletal related event"
              )}
            />
          </InputWrapper>
          <InputWrapper>
            <SelectInput
              label={t("Treatment")}
              value={values.treatment}
              name="treatment"
              id="treatment"
              onChange={handleChange}
              onBlur={handleBlur}
              selectOptions={treatments}
            />
            <SelectInput
              label={t("Self-Reported Race")}
              value={values.race}
              name="race"
              id="race"
              onChange={handleChange}
              onBlur={handleBlur}
              selectOptions={races}
            />
          </InputWrapper>
          <InputWrapper>
            <InputGroup>
              <Label htmlFor="com1">{t("Comorbidity")}</Label>
              <Radio
                label={t("Yes")}
                type="radio"
                id="com1"
                name="com"
                value="yes"
                onChange={handleChange}
                checked={values.com === "yes"}
                onBlur={handleBlur}
                description={t(
                  "Includes lung disease, heart disease, other cancer diagnoses"
                )}
              />
              <Radio
                label={t("No")}
                id="com2"
                name="com"
                value="no"
                checked={values.com === "no"}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
          </InputWrapper>
          <Label htmlFor="alkphosdate1">
            Calculate Alkaline Phosphatase Velocity
          </Label>
          <InputWrapper>
            <InputGroup>
              <Label htmlFor="alkphosdate1">Date</Label>

              <DateInput
                value={values.alkphosdate1}
                name="alkphosdate1"
                updateValue={updateValueCallback}
              />
            </InputGroup>
            <InputGroup>
              <NumberInput
                label={
                  t("Alkaline Phosphatase Level") +
                  (values.alkphosdate1
                    ? " at " + format(values.alkphosdate1)
                    : "")
                }
                id="alkphos1"
                value={values.alkphos1}
                min={0}
                max={1000}
                name="alkphos1"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                inputFormat="IU/L"
              />
            </InputGroup>
          </InputWrapper>
          <InputWrapper>
            <InputGroup>
              <Label htmlFor="alkphosdate2">Date</Label>
              <DateInput
                value={values.alkphosdate2}
                name="alkphosdate2"
                updateValue={updateValueCallback}
              />
            </InputGroup>

            <InputGroup>
              <NumberInput
                label={
                  t("Alkaline Phosphatase Level") +
                  (values.alkphosdate2
                    ? " at " + format(values.alkphosdate2)
                    : "")
                }
                value={values.alkphos2}
                min={0}
                max={1000}
                id="alkphos2"
                name="alkphos2"
                onChange={handleChange}
                onBlur={handleBlur}
                required
                inputFormat="IU/L"
              />
            </InputGroup>
          </InputWrapper>
          <InputWrapper>
            <InputGroup>
              <Label htmlFor="alkphosdate1">
                {t("Alkaline Phosphatase Velocity (APV)")}
              </Label>
              <Paragraph>{apvLabel}</Paragraph>
            </InputGroup>
          </InputWrapper>
          <Button type="submit" size="big">
            {t("Calculate Survival Trajectory")}
          </Button>
          {onSubmitting && (
            <div className={styles.loadingContainer}>
              <CircularProgress size={68} />
            </div>
          )}
        </Fieldset>
      </form>
    </>
  );
};

export default ProstateForm;

const initialValues = {
  proximalPSA: 4.0,
  metsAge: 50,
  com: "yes",
  treatment: "txNO",
  apv: "apv1Quick",
  race: "race1CAandOT",
  alkphos2: 0,
  alkphos1: 0,
  alkphosdate1: null,
  alkphosdate2: null,
};

/**
 * @param {{ proximalPSA: any; metsAge: any; com: any; treatment: string; apv: string; race: string; }} values
 */
function compileForm(values) {
  return {
    proximalPSA: Number(values.proximalPSA),
    metsAge: Number(values.metsAge),
    com: values.com === "yes",
    txNO: values.treatment === "txNO",
    txRT: values.treatment === "txRT",
    txHTCHEMO: values.treatment === "txHTCHEMO",
    apv1Quick: values.apv === "apv1Quick",
    apv1Slow: values.apv === "apv1Slow",
    apv1NoRising: values.apv === "apv1NoRising",
    race1CAandOT: values.race === "race1CAandOT",
    race1AA: values.race === "race1AA",
  };
}

const races = [
  {
    value: "race1CAandOT",
    label:
      "Caucasian/Other (includes Asian, Filipino, Hispanic, Native American)",
  },
  { value: "race1AA", label: "African American" },
];

const treatments = [
  { value: "txNO", label: "Naïve (no treatment)" },
  { value: "txRT", label: "Radiotherapy" },
  { value: "txHTCHEMO", label: "Chemotherapy" },
];

const apvs = [
  { value: "apv1Quick", label: "Quick rising" },
  { value: "apv1Slow", label: "Slow rising" },
  { value: "apv1NoRising", label: "No rising" },
];
