import { AuthContext } from "auth/AuthProvider";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Section, Title } from "ui/";

const Logout = () => {
  const { t } = useTranslation();
  const { token, setToken } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      setToken(null);
    }
  });

  return (
    <div>
      <Section>
        <Layout position="topCenter">
          <Title>{t("Logout")}</Title>
          <p>{t("You have now been logged out")}.</p>
        </Layout>
      </Section>
    </div>
  );
};

export default Logout;
