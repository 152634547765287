import React from "react";
import s from "./Burger.module.scss";
import classnames from "classnames";

const Burger = ({ menuHandler }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleBurger = () => {
    setIsOpen((oldValue) => !oldValue);
    menuHandler();
  };

  return (
    <>
      <button
        onClick={toggleBurger}
        className={classnames(s.root, { [s.isOpen]: isOpen })}
      >
        <div />
        <div />
        <div />
      </button>
    </>
  );
};

export default Burger;
