import React from "react";
import styles from "./input.module.scss";

const Select = (props) => {
  const { className, ...otherProps } = props;
  const mergeClassName = styles.input + (className ? " " + className : "");
  return <select className={mergeClassName} {...otherProps} />;
};

export default Select;
