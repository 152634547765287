import React from "react";
import Skeleton from "ui/Skeleton/Skeleton";
import { Layout, Section, Title } from "ui/";
import LoginForm from "components/Forms/LoginForm/LoginForm";

const Login = ({ page }) => {
  return (
    <div>
      <Section>
        <Layout position="topCenter">
          <Title>
            {page ? page?.sections[0]?.fields?.title : <Skeleton />}
          </Title>
          <LoginForm />
        </Layout>
      </Section>
    </div>
  );
};

export default Login;
