import React from "react";
import { Heading, Layout, Paragraph, Section, Skeleton } from "ui";

const SectionTitleAndParagraph = ({
  section,
  bgColor,
  narrow = true,
  children = undefined,
}) => {
  return (
    <Section bgColor={bgColor}>
      <Layout position="top" narrow={narrow}>
        <Heading>{section ? section.title : <Skeleton />}</Heading>
        <Paragraph color="grey">
          {section ? section.paragraph : <Skeleton />}
        </Paragraph>
        {children}
      </Layout>
    </Section>
  );
};

export default SectionTitleAndParagraph;
