import React from "react";
import PropTypes from "prop-types";
import styles from "./spotlightTitle.module.scss";
import classNames from "classnames";

const SpotlightTitle = ({ children, color, href }) => {
  const SpotlightTitleClassNames = classNames(styles.spotlightTitle, {
    [styles.white]: color === "white",
    [styles.purple]: color === "purple",
  });
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <h2 className={SpotlightTitleClassNames}>{children}</h2>
    </a>
  );
};

SpotlightTitle.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  color: PropTypes.oneOf(["white", "purple"]),
};

export default SpotlightTitle;
