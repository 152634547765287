import React from "react";

import styles from "./basepage.module.scss";
import { Layout, Paragraph, Section, Skeleton, Title } from "ui";

export const QueryBasePageSection = ({ page, children = undefined }) => {
  return (
    <Section bgColor="lightWhite">
      <Layout narrow={true} position="topCenter">
        <Title>{page ? page?.pageTitle : <Skeleton />}</Title>
        <Paragraph color="purple">
          {page ? page?.pageDescription : <Skeleton />}
        </Paragraph>
        {children}
      </Layout>
    </Section>
  );
};

const QueryBasePage = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props?.match?.path]);

  return (
    <div className={styles.basepage}>
      <main role="main">{props.component}</main>
    </div>
  );
};

export default QueryBasePage;
