import React from "react";
import { LinkButton } from "ui/";
import { BasePageSection } from "pages/BasePage";
import { DynamicSection } from "page-components/DynamicSection";
import { useTranslation } from "react-i18next";

const HowToUse = ({ page }) => {
  const sections = page ? page.sections : Array(3);
  const { t } = useTranslation();
  return (
    <>
      <BasePageSection page={page}>
        <LinkButton href="https://accounts.medbullets.com/account/login?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dimplicit.cp.ob%26redirect_uri%3Dhttps%253A%252F%252Fwww.orthobullets.com%252Fsignin-oidc%26response_mode%3Dform_post%26response_type%3Did_token%2520token%26scope%3Dopenid%2520profile%2520email%26state%3DOpenIdConnect.AuthenticationProperties%253DVqQ3lYQywA0tlR0uw8z02tIYg9FDIDhN09ljQZBniZrecXkY2UI3tg7yQ4RPBlv8k_k90whVf3ZZ-gUHrxaJpmTcK0xxAn3dHUI-ebfcL-ZCchjpPvQNMlHNfGr5jN-eQQ0GkoRnTm_601SBf4Gfuy2i1ErrKfyeetJqXwCCiGgkDI4pbc7WcTBgv6Yvb4iIGO73TJe8AfVL_x9OqV-PCQO8OFplTIskWFcrnGT6ovdZ4vb3AGiOF6BL4WrUWuFZqmrq1zkgn0pbiAlnf8F_o3-SBXE%26nonce%3D637558891403211402.YmZiZTkwMTEtMmRkYy00MTczLTg5NzctYTc2MjRmMmVkMmJiZGFlNDUwMzgtM2I0NS00ZmQ3LTgyNTctMTc2Mzc1N2E3M2Uw">
          {t("Review case tutorial in OrthoBullets")}
        </LinkButton>
      </BasePageSection>
      {sections.map((section, idx) => {
        return (
          <DynamicSection key={idx} section={section} idx={idx} narrow={true} />
        );
      })}
    </>
  );
};

export default HowToUse;
