import React from "react";
import PropTypes from "prop-types";
import { GetButtonClassNames } from "./Button";

const TextButton = ({ className = undefined, children, onClick }) => {
  return (
    <>
      <button
        className={
          GetButtonClassNames("link") + (className ? " " + className : "")
        }
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
};

TextButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default TextButton;
