import React from "react";
import { Heading, Paragraph, Image, Skeleton } from "ui/";

import styles from "./scenarioItem.module.scss";

const ScenarioItem = ({ scenario }) => {
  const photoList = scenario ? scenario.photos : [undefined];

  const images = photoList.map((p, i) => (
    <Image
      key={i}
      size="small"
      imgSrc={scenario ? "https:" + p.fields.file.url : undefined}
    ></Image>
  ));

  return (
    <div className={styles.scenarioItem}>
      <div>
        <Heading>{scenario ? scenario.name : <Skeleton />}</Heading>
        <Paragraph color="grey">
          {scenario ? scenario.description : <Skeleton count={3} />}
        </Paragraph>
        <Paragraph color="grey">
          {scenario && scenario.manufacturer_name && (
            <Manufacturer scenario={scenario} />
          )}
        </Paragraph>
      </div>
      {images}
    </div>
  );
};

export default ScenarioItem;

const Manufacturer = ({ scenario }) => {
  return scenario.manufacturer_link ? (
    <a
      href={scenario.manufacturer_link}
      target="_blank"
      rel="nofollow noreferrer"
    >
      {scenario.manufacturer_name}
    </a>
  ) : (
    scenario.manufacturer_name
  );
};
