import React from "react";
import PropTypes from "prop-types";
import styles from "./paragraph.module.scss";
import classNames from "classnames";

const Paragraph = ({ children, color }) => {
  const ParagraphClassNames = classNames(styles.paragraph, {
    [styles.grey]: color === "grey",
    [styles.white]: color === "white",
    [styles.purple]: color === "purple",
  });

  return <p className={ParagraphClassNames}>{children}</p>;
};

Paragraph.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(["grey", "white", "purple"]),
};

export default Paragraph;
