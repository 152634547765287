import { AuthContext } from "auth/AuthProvider";
import BasePage from "pages/BasePage";
import React, { useContext } from "react";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";

const ProtectedRoute = ({
  title,
  component: Component,
  requireAdmin = false,
  ...otherProps
}) => {
  const { token, userData } = useContext(AuthContext);

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (!token || (requireAdmin && userData?.account_type !== "Admin")) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return (
            <BasePage title={title} component={<Component {...props} />} />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
