import React from "react";
import NewsTicker from "react-advanced-news-ticker";
import { HeroTitleSecondary, SpotlightTitle } from "ui";
import styles from "./spotlight.module.scss";

const Spotlight = ({ heroContent }) => {
  return (
    <div className={styles.spotlight}>
      <HeroTitleSecondary>{heroContent?.spotlightTitle}</HeroTitleSecondary>
      {heroContent && (
        <NewsTicker rowHeight={160} maxRows={1} duration={4000}>
          {heroContent?.spotlightEntries?.map((s, i) => (
            <SpotlightTitle href={s?.fields?.listItemUrl} key={i}>
              {s?.fields?.listItemName}
            </SpotlightTitle>
          ))}
        </NewsTicker>
      )}
    </div>
  );
};

export default Spotlight;
