import ResetPasswordForm from "components/Forms/ResetPasswordForm/ResetPasswordForm";
import React from "react";
import { Section, Layout } from "ui/";
const ForgotPassword = () => {
  return (
    <Section>
      <Layout position="topCenter">
        <ResetPasswordForm />
      </Layout>
    </Section>
  );
};

export default ForgotPassword;
