import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "ui/";
import styles from "./consent.module.scss";

function setCookie() {
  const date = new Date();
  date.setDate(new Date().getDate() + 365);
  document.cookie = "pfx-privacy-consent=true; expires=" + date.toUTCString();
}

export function getPrivacyCookie() {
  return document.cookie
    .split(";")
    .find((c) => c.trim().startsWith("pfx-privacy-consent"));
}

const Consent = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(!getPrivacyCookie());

  return (
    visible && (
      <div className={styles.consent}>
        <span>
          {t("This website uses cookies to improve your experience.")} &nbsp;
        </span>
        <Button
          type="button"
          size="small"
          color="darkgreen"
          onClick={() => {
            setCookie();
            setVisible(false);
            window.location.reload();
          }}
        >
          {t("Accept")}
        </Button>
        <Button
          type="button"
          size="small"
          color="darkgreen"
          onClick={() => {
            setVisible(false);
          }}
        >
          {t("Reject")}
        </Button>
      </div>
    )
  );
};
export default Consent;
