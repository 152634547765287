import React, { useContext, useState } from "react";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import { supportedLngs } from "Language";
import { LanguageContext } from "LanguageProvider";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
    color: "white",
    "& fieldset": {
      borderColor: "white",
      borderWidth: "2px",
      opacity: 0.8,
      borderRadius: 0,
    },
    "& .MuiSelect-icon, .MuiSelect-select": {
      color: "white",
    },
    "& .MuiOutlinedInput-input": {
      padding: "0.25rem 1rem",
      borderRadius: 0,
      display: "flex",
      lineHeight: "1.5em",
      fontFamily: "franklin-gothic-urw-cond, Franklin Gothic, sans-serif",
      fontSize: "1em",
    },
  },
}));

const LanguageSelector = () => {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);
  const [language, setLanguage] = useState(userLanguage);
  const classes = useStyles();

  const onLanguageChangeHandler = (event) => {
    const value = event.target.value;
    userLanguageChange(value);
    setLanguage(value);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="language-selector-label"
        id="language-selector"
        onChange={onLanguageChangeHandler}
        value={language}
        variant="outlined"
      >
        {supportedLngs.map((language) => {
          return (
            <MenuItem key={language.code} value={language.code}>
              <LanguageOutlinedIcon />
              &nbsp;{language.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
