import React from "react";
import styles from "./menuItem.module.scss";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";

const MenuItem = ({ itemText, itemStyle, path }) => {
  const MenuItemClassNames = classNames(styles.listItem, {
    [styles.fill]: itemStyle === "fill",
    [styles.outlined]: itemStyle === "outlined",
  });

  return (
    <li className={styles.menuItem}>
      <NavLink
        to={path}
        className={MenuItemClassNames}
        activeClassName={styles.activeLink}
      >
        {itemText}
      </NavLink>
    </li>
  );
};

MenuItem.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string,
  itemStyle: PropTypes.oneOf(["fill", "outlined", undefined]),
};
export default MenuItem;
