import React from "react";
import PropTypes from "prop-types";

import styles from "./image.module.scss";
import classNames from "classnames";
import Skeleton from "../Skeleton/Skeleton";

const Image = ({ imgSrc, altTxt, size }) => {
  const ImageClassNames = classNames(styles.image, {
    [styles.small]: size === "small",
  });

  return imgSrc ? (
    <img className={ImageClassNames} src={imgSrc} alt={altTxt} />
  ) : (
    <Skeleton minHeight="300px" block={true} />
  );
};

Image.propTypes = {
  imgSrc: PropTypes.string,
  altTxt: PropTypes.string,
  size: PropTypes.oneOf(["small"]),
};

export default Image;
