import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./assets/locales/en/translation.json";
import sv from "./assets/locales/sv/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  "en-US": { translation: en },
  "sv-SE": { translation: sv },
};

export const supportedLngs = [
  { code: "en-US", name: "English" },
  { code: "sv-SE", name: "Svenska" },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resources,
    fallbackLng: "en-US",
    debug: false,
    keySeparator: false,
    returnEmptyString: false,
    supportedLngs: supportedLngs.map((l) => l.code),
  });

export default i18n;
