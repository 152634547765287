export const genders = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const oncologics = [
  {
    label: "Bladder",
    value: 2.1,
  },
  {
    label: "Breast",
    value: 3.1,
  },
  {
    label: "Coloreactal",
    value: 2.2,
  },
  {
    label: "Gastric",
    value: 1.1,
  },
  {
    label: "Kidney/Renal",
    value: 3.2,
  },
  {
    label: "Liver/Hepatocellular",
    value: 1.2,
  },
  {
    label: "(Lung) Non-Small Cell",
    value: 1.3,
  },
  {
    label: "(Lung) Small Cell",
    value: 1.4,
  },
  {
    label: "(Lung) Unknown",
    value: 1.5,
  },
  {
    label: "Lymphoma",
    value: 3.3,
  },
  {
    label: "Melanoma",
    value: 1.6,
  },
  {
    label: "Myeloma",
    value: 3.4,
  },
  {
    label: "Nasophyaryngeal Carcinoma",
    value: 2.3,
  },
  {
    label: "Prostate",
    value: 3.5,
  },
  {
    label: "Thyroid",
    value: 3.6,
  },
  {
    label: "Unknown/Other",
    value: 2.4,
  },
];

export const pathologics = [
  {
    label: "Yes (Completed)",
    value: "yes",
  },
  {
    label: "No (Impending)",
    value: "no",
  },
];

export const skeletalMetastasesOptions = [
  {
    label: "Solitary",
    value: "solitary",
  },
  {
    label: "Multiple",
    value: "multiple",
  },
];

export const organMetastasesOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];

export const lymphNodeMetastasesOptions = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
];
