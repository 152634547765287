import React from "react";
import styles from "./inputText.module.scss";

const InputText = ({ children, ...props }) => {
  return (
    <span className={styles.inputText} {...props}>
      {children}
    </span>
  );
};

export default InputText;
