import React from "react";
import styles from "./mobileMenu.module.scss";
import classNames from "classnames";

const MobileMenu = ({ children, menu }) => {
  const MobileMenuClassNames = classNames(styles.mobileMenu, {
    [styles.transl]: menu === true,
  });

  return (
    <nav className={MobileMenuClassNames}>
      <ul>{children}</ul>
    </nav>
  );
};

export default MobileMenu;
