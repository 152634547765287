import React from "react";
import styles from "./numberInput.module.scss";
import PropTypes from "prop-types";
import Description from "ui/Form/Description/Description";
import Label from "ui/Form/Label";
import Input from "ui/Form/Input";

const NumberInput = ({
  onChange,
  onBlur,
  label,
  value,
  name,
  id,
  min = undefined,
  max = undefined,
  description = undefined,
  step = undefined,
  isOptional = false,
  inputFormat = undefined,
  ...props
}) => {
  const onFocus = (event) => event.target.select();
  return (
    <div className={styles.inputWrapper}>
      <Label htmlFor={id}>
        {label}
        {isOptional ? (
          <span className={styles.optional}>(optional)</span>
        ) : null}
      </Label>
      <Input
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        type="number"
        value={value}
        min={min}
        max={max}
        name={name}
        id={id}
        step={step}
        {...props}
      />
      {inputFormat ? (
        <span className={styles.inputFormat}>{inputFormat}</span>
      ) : null}
      {description ? <Description>{description}</Description> : null}
    </div>
  );
};

NumberInput.propTypes = {
  changeHandler: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.number,
  max: PropTypes.number,
  name: PropTypes.string,
  id: PropTypes.string,
  description: PropTypes.string,
  step: PropTypes.string,
  isOptional: PropTypes.bool,
  inputFormat: PropTypes.string,
};
export default NumberInput;
