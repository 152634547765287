import React from "react";
import styles from "./radioInput.module.scss";
import PropTypes from "prop-types";

const RadioInput = ({
  children,
  onChange,
  onBlur,
  checked,
  value,
  name,
  id,
  required = undefined,
}) => {
  return (
    <div className={styles.radioWrapper} role="radiogroup">
      <input
        onChange={onChange}
        onBlur={onBlur}
        type="radio"
        value={value}
        checked={checked}
        name={name}
        id={id}
        required={required}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

RadioInput.propTypes = {
  children: PropTypes.string,
  changeHandler: PropTypes.func,
  checked: PropTypes.bool,
};

export default RadioInput;
