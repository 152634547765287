import React from "react";
import { Container, Layout, SectionWithBackground, Heading } from "ui/";
import FooterBG from "assets/images/footer.jpg";
import QuickLinksItem from "./QuickLinksItem";
import QuickLinks from "./QuickLinks";
import { useTranslation } from "react-i18next";
import styles from "./footer.module.scss";
import CELogo from "assets/images/ce_logo.svg";
import RefLogo from "assets/images/ref-logo.svg";
import ManufacturerLogo from "assets/images/manufacturer-logo.svg";
import MDLogo from "assets/images/md-logo.svg";
import BookLogo from "assets/images/book-logo.svg";
import ContactForm from "components/Forms/ContactForm/ContactForm";
import Copyright from "page-components/Copyright/Copyright";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={styles.footer}>
      <SectionWithBackground bgUrl={FooterBG}>
        <Layout position="left">
          <Container>
            <Heading color="darkPurple">{t("Have questions?")}</Heading>
            <ContactForm />
            <Copyright>
              {t("Copyright © 2021 PATHFx")}. {t("All rights reserved")}.
            </Copyright>
          </Container>
          <Container>
            <div>
              <Heading color="darkPurple">{t("Quick Links")}</Heading>
              <QuickLinks>
                <QuickLinksItem path="/about">{t("About")}</QuickLinksItem>
                <QuickLinksItem path="/how-to-use">
                  {t("How to Use")}
                </QuickLinksItem>
                <QuickLinksItem path="/how-it-works">
                  {t("How it Works")}
                </QuickLinksItem>
              </QuickLinks>
            </div>
            <br />
            <br />
            <div className={styles.CeContainer}>
              <Heading color="white">PATHFx 3.0</Heading>
              <div className={styles.refContainer}>
                <img src={RefLogo} alt="Ref Logo" width="60px" />
                <img src={BookLogo} alt="Information Logo" width="60px" />
              </div>
              <br />
              <address className={styles.adressContainer}>
                <img
                  src={ManufacturerLogo}
                  alt="Manufacturer Logo"
                  width="50px"
                />
                Prognostix AB
                <br />
                Sevensvägen 19
                <br />
                SE-174 46, Sundbyberg, Sweden
                <br />
              </address>
              <br />
              <br />
              <div className={styles.ceSymbols}>
                <img src={CELogo} alt="Ce Logo" width="50px" />
                <img src={MDLogo} alt="MD Logo" width="60px" />
                <br />
                <br />
              </div>
            </div>
          </Container>
        </Layout>
      </SectionWithBackground>
    </footer>
  );
};

export default Footer;
