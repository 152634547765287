import classNames from "classnames";
import React from "react";
import styles from "./fieldset.module.scss";

const Fieldset = ({ children, disabled, size = undefined }) => {
  const LayoutClassNames = classNames(styles.fieldset, {
    [styles.lg]: size === "lg",
    [styles.md]: size === "md",
  });
  return (
    <fieldset disabled={disabled} className={LayoutClassNames}>
      {children}
    </fieldset>
  );
};

export default Fieldset;
