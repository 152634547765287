import React from "react";
import styles from "./input.module.scss";
import Label from "./Label";

const Radio = ({ id, label, description = undefined, ...props }) => {
  return (
    <>
      <div className={styles.radioInput}>
        <input type="radio" id={id} {...props} />
        <Label htmlFor={id}>
          {label}
          {description && <small>{description}</small>}
        </Label>
      </div>
    </>
  );
};

export default Radio;
