import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import {
  About,
  Home,
  HowToUse,
  HowItWorks,
  Login,
  Register,
  ShareACase,
  Logout,
  BasePage,
  ForgotPassword,
  EmbeddedEstimatePage,
  EstimatePage,
  ChangePassword,
  UsersPage,
} from "./pages";
import { AuthProvider } from "auth/AuthProvider";
import GoogleTagManager from "page-components/analytics/GoogleTagManager";
import Recaptcha from "page-components/recaptcha/recaptcha";
import ProtectedRoute from "components/Route/ProtectedRoute";
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getPrivacyCookie } from "page-components/Privacy/Consent";
import LanguageProvider, { LanguageContext } from "LanguageProvider";
import useContentfulClient from "hook/Contentful/useContentfulClient";
import QueryProtectedRoute from "components/Route/QueryProtectedRoute";
const theme = createMuiTheme({});

const AppData = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  const { userLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();
  const client = useContentfulClient();
  React.useEffect(() => {
    if (isLoading) {
      GetEntries(client, userLanguage).then((response) => {
        setPages(response.items.reverse());
      });
    }
    setIsLoading(true);
  }, [isLoading, userLanguage]);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(props) => (
          <BasePage
            {...props}
            home={true}
            title={t("Home")}
            component={
              <Home
                page={pages.find((p) => p?.sys.id === pageIds.home)?.fields}
              />
            }
          />
        )}
      />
      <Route
        path="/about"
        exact
        render={(props) => {
          return (
            <BasePage
              {...props}
              title={t("About")}
              component={
                <About
                  page={pages.find((p) => p?.sys.id === pageIds.about)?.fields}
                />
              }
            />
          );
        }}
      />
      <Route
        path="/how-to-use"
        exact
        render={(props) => (
          <BasePage
            {...props}
            title={t("How to Use")}
            component={
              <HowToUse
                page={pages.find((p) => p?.sys.id === pageIds.howToUse)?.fields}
              />
            }
          />
        )}
      />
      <Route
        path="/how-it-works"
        exact
        render={(props) => (
          <BasePage
            {...props}
            title={t("How it Works")}
            component={
              <HowItWorks
                page={
                  pages.find((p) => p?.sys.id === pageIds.howItWorks)?.fields
                }
              />
            }
          />
        )}
      />
      <Route
        path="/share-a-case"
        exact
        render={(props) => (
          <BasePage
            {...props}
            title={t("Interesting Case")}
            component={
              <ShareACase
                page={
                  pages.find((p) => p?.sys.id === pageIds.shareACase)?.fields
                }
              />
            }
          />
        )}
      />
      <Route
        path="/login"
        exact
        render={(props) => (
          <BasePage
            {...props}
            title={t("Login")}
            component={
              <Login
                page={pages.find((p) => p?.sys.id === pageIds.login)?.fields}
              />
            }
          />
        )}
      />
      <Route
        path="/logout"
        exact
        render={(props) => (
          <BasePage {...props} title={t("Logout")} component={<Logout />} />
        )}
      />
      <Route
        path="/register"
        exact
        render={(props) => (
          <BasePage {...props} title={t("Register")} component={<Register />} />
        )}
      />
      <Route
        exact
        path="/forgot-password"
        render={(props) => (
          <BasePage
            {...props}
            title={t("Forgot password")}
            component={<ForgotPassword />}
          />
        )}
      />
      <Route
        path={["/change-password/:token", "/change-password"]}
        render={(props) => (
          <BasePage
            title={t("Change password")}
            component={<ChangePassword {...props} />}
          />
        )}
      />
      <ProtectedRoute
        exact
        title={t("Estimate Survival")}
        path="/estimate-survival"
        component={EstimatePage}
      />
      <QueryProtectedRoute
        exact
        title={t("Estimate Survival")}
        path="/prediction-external"
        component={EmbeddedEstimatePage}
      />
      <ProtectedRoute
        requireAdmin
        exact
        title={t("Users")}
        path="/users"
        component={UsersPage}
      />
      <Route render={() => Error} />
    </Switch>
  );
};

const App = () => {
  var consentExists = getPrivacyCookie();

  return (
    <LanguageProvider>
      {consentExists && <GoogleTagManager />}
      {consentExists && <Recaptcha />}
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <AppData />
        </AuthProvider>
      </ThemeProvider>
    </LanguageProvider>
  );
};

export default App;

const GetEntries = (client, userLanguage) => {
  return client.getEntries({
    content_type: "page",
    locale: userLanguage,
    include: 2,
  });
};

const pageIds = {
  home: "2Lp6eTlSXFvzatWWoKjZs9",
  about: "4KJ1f5iDpgzSU6E1HVowtT",
  howToUse: "2srb1EyKtvINRLnzh9ecsh",
  howItWorks: "6IcCiXnWtwpPZbHDDswx7f",
  shareACase: "3gmwdgCci80g6LoYdmXt0",
  login: "7r9GwgIYCt5RIxMn05Jwij",
};
