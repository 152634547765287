import React from "react";
import styles from "./list.module.scss";

const List = (props) => {
  return <ul className={styles.list}>{props.children}</ul>;
};

export default List;

export const ListItem = (props) => {
  return <li className={styles.listItem}>{props.children}</li>;
};
