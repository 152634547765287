import React from "react";
import styles from "./input.module.scss";

const Textarea = (props) => {
  const { className, ...otherProps } = props;
  const mergeClassName = styles.input + (className ? " " + className : "");
  return <textarea className={mergeClassName} {...otherProps} />;
};

export default Textarea;
