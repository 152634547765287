import React from "react";
import { Heading, Paragraph } from "ui";
import Tabs from "ui/Tabs/Tabs";
import Region from "./Region/Region";
import styles from "./skeletalRegions.module.scss";

const SkeletalRegions = ({ regions, onRegionSelect }) => {
  const groups = regions.map((r) => r.fields.group).filter(onlyUnique);
  const tabs = groups.map((g) => {
    return {
      title: g,
      content: (
        <SkeletalRegion
          onRegionSelect={onRegionSelect}
          regions={regions.filter((gr) => gr.fields.group === g)}
        />
      ),
    };
  });

  return (
    <div className={styles.skeletalRegions}>
      <div className={styles.titleContainer}>
        <Heading>Select your skeletal region</Heading>
        <Paragraph color="grey">
          There may be many ways to treat individual lesions. This application
          considers both the location of the bone lesion as well as your
          patient’s survival trajectory.
        </Paragraph>
      </div>
      <div className={styles.tabsContainer}>
        {tabs && tabs.length > 0 && <Tabs tabs={tabs} />}
      </div>
    </div>
  );
};

export default SkeletalRegions;
const SkeletalRegion = ({ regions, onRegionSelect }) => {
  return regions.map((region) => (
    <Region
      key={region.fields.name}
      region={region.fields}
      onRegionSelect={onRegionSelect}
    />
  ));
};

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
