import React from "react";
import PropTypes from "prop-types";
import styles from "./heroTitle.module.scss";
import classNames from "classnames";

const HeroTitle = ({ children, color }) => {
  const HeroTitleClassNames = classNames(styles.heroTitle, {
    [styles.white]: color === "white",
    [styles.purple]: color === "purple",
  });
  return <h1 className={HeroTitleClassNames}>{children}</h1>;
};

HeroTitle.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(["white", "purple"]),
};

export default HeroTitle;
