import React from "react";
import styles from "./orderedListItem.module.scss";
import PropTypes from "prop-types";

const OrderedListItem = ({ children }) => {
  return <li className={styles.listItem}>{children}</li>;
};

OrderedListItem.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

export default OrderedListItem;
