import QueryBasePage from "pages/QueryBasePage";
import React from "react";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";

const QueryProtectedRoute = ({
  title,
  component: Component,
  ...otherProps
}) => {
  const queryToken = otherProps.location.search;

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (queryToken !== "?UJw0RsjGQK3sjUTdbRPUFN9I1v1m3ovk") {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        } else {
          return (
            <QueryBasePage title={title} component={<Component {...props} />} />
          );
        }
      }}
    />
  );
};

export default QueryProtectedRoute;
