import React from "react";
import Description from "../Description/Description";
import styles from "./selectInput.module.scss";
import PropTypes from "prop-types";
import Label from "ui/Form/Label";
import Select from "../Select";
import { useTranslation } from "react-i18next";

const SelectInput = ({
  label,
  value,
  name,
  id,
  onChange,
  onBlur,
  selectOptions,
  description,
  isOptional = false,
  canBeUndefined = false,
  disabledOptions = [],
}) => {
  const { t } = useTranslation();
  const isDisabled = (option) => {
    return (
      disabledOptions.find(
        (d) => d.value.toString() === option.value.toString()
      ) !== undefined
    );
  };
  return (
    <div className={styles.selectWrapper}>
      <Label htmlFor={id}>
        {label}
        {isOptional ? (
          <span className={styles.optional}>(optional)</span>
        ) : null}
      </Label>
      <Select
        value={value}
        name={name}
        id={id}
        onChange={onChange}
        onBlur={onBlur}
      >
        {canBeUndefined && <option value={undefined}>-</option>}
        {selectOptions.map((option) => {
          const disabled = isDisabled(option);
          return (
            <option
              disabled={disabled}
              key={option.label}
              value={option.value || option.label}
            >
              {t(option.label)}
            </option>
          );
        })}
      </Select>
      {description ? <Description>{description}</Description> : null}
    </div>
  );
};

SelectInput.propTypes = {
  changeHandler: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selectOptions: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
  description: PropTypes.string,
  isOptional: PropTypes.bool,
  canBeUndefined: PropTypes.bool,
};

export default SelectInput;
