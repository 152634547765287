import React from "react";
import PropTypes from "prop-types";
import styles from "./button.module.scss";
import classNames from "classnames";

/**
 * @param {string} color
 * @param {string} size
 */

export function GetButtonClassNames(color, size = undefined, outlined = false) {
  return classNames(styles.button, {
    [styles.green]: color === "green",
    [styles.blue]: color === "blue",
    [styles.darkGreen]: color === "darkgreen",
    [styles.outlined]: outlined === true,
    [styles.small]: size === "small",
    [styles.link]: color === "link",
    [styles.big]: size === "big",
  });
}

const Button = ({
  children,
  color = "green",
  outlined = false,
  onClick = undefined,
  onSubmit = undefined,
  size = undefined,
  type,
  className = undefined,
  ...otherProps
}) => {
  if (!type) {
    type = "button";
  }
  if (onSubmit && !type) {
    type = "submit";
  }
  return (
    <>
      <button
        onClick={onClick}
        onSubmit={onSubmit}
        type={type}
        className={
          GetButtonClassNames(color, size, outlined) +
          (className ? " " + className : "")
        }
        {...otherProps}
      >
        {children}
      </button>
    </>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "big"]) || undefined,
  color: PropTypes.oneOf(["green", "blue", "darkgreen"]) || undefined,
  type: PropTypes.oneOf(["button", "submit"]),
  onClick: PropTypes.func || undefined,
  onSubmit: PropTypes.func || undefined,
  className: PropTypes.string,
};

export default Button;
