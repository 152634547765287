import React, { useState, useEffect } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withStyles } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { format as formatter } from "date-fns";

const DateInput = ({ name, updateValue, value, classes }) => {
  const [val, setVal] = useState(value);

  useEffect(() => {
    updateValue(name, val);
  }, [val]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <DatePicker
          style={{ width: "100%" }}
          id={name}
          value={val}
          name={name}
          onChange={(val) => {
            setVal(val);
          }}
          format={dateFormat}
          animateYearScrolling
          inputVariant="outlined"
          variant="inline"
          InputProps={{
            classes: {
              focused: classes.focused,
              notchedOutline: classes.notchedOutline,
              input: classes.input,
              root: classes.root,
            },
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

const stylesFn = () => {
  return {
    input: {
      boxShadow: "0 5px 16px rgba(0, 0, 0, 0.1)",
      width: "100%",
    },
    focused: {
      borderColor: "#91cfc8",
    },
    notchedOutline: {
      borderWidth: "0",
      borderColor: "#91cfc8",
    },
  };
};

const dateFormat = "MMMM dd, yyyy";
export const format = (date) => {
  return formatter(date, dateFormat);
};

export default withStyles(stylesFn)(DateInput);
