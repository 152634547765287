const defaultParams = {
  dataLayerName: "dataLayer",
  id: "G-T52GF4G6YV",
};

const GoogleTagManager = () => {
  initGTM(defaultParams);
  return null;
};

/**
 * Function to init the GTM
 * @param {object} gtm
 * @param gtm.dataLayerName - The dataLayer name
 * @param gtm.id - The ID of the GTM
 */
export const initGTM = ({ dataLayerName, id }) => {
  if (document.getElementById("gtag")) {
    return;
  }

  const gtm = setupGTM({
    dataLayerName,
    id,
  });

  const script = gtm.getScript();
  document.head.insertBefore(gtm.getAnalytics(), document.head.childNodes[0]);
  document.head.insertBefore(script, document.head.childNodes[0]);
};

/**
 * Function to send the events to the GTM
 * @param {object} gtm
 * @param {string} gtm.dataLayerName - The dataLayer name
 * @param gtm.data - The data to push
 */
export const sendToGTM = ({ dataLayerName, data }) => {
  if (!window[dataLayerName]) {
    window[dataLayerName] = [];
  }
  window[dataLayerName].push(data);
};
const setupGTM = (params) => {
  const getScript = () => {
    const script = document.createElement("script");
    script.setAttribute("id", "gtag");
    script.innerHTML =
      getDataLayerSnippet(params.dataLayerName) +
      getGTMScript(params.dataLayerName, params.id);
    return script;
  };

  const getAnalytics = () => {
    const script = document.createElement("script");
    script.setAttribute("id", "google");
    script.setAttribute("async", "async");
    script.setAttribute(
      "src",
      "https://www.googletagmanager.com/gtag/js?id=" + params.id
    );
    return script;
  };

  return {
    getScript,
    getAnalytics,
  };
};

/**
 * Function to get and set dataLayer
 * @param dataLayerName - The dataLayer name
 */
export const getDataLayerSnippet = (dataLayerName = "dataLayer") =>
  `window.${dataLayerName}=window.${dataLayerName}||[];`;

/**
 * Function to get the GTM script
 * @param dataLayerName - The name of the dataLayer
 * @param id - The id of the container
 */
export const getGTMScript = (dataLayerName, id) => {
  return `function gtag(){window['${dataLayerName}'].push(arguments);};gtag('js', new Date());gtag('config', '${id}');`;
};

export default GoogleTagManager;
