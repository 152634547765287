import "formdata-polyfill";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { sitekey } from "page-components/recaptcha/recaptcha";
import { Button } from "ui/";
import {
  Input,
  Label,
  Radio,
  Fieldset,
  InputGroup,
  Description,
} from "ui/Form";
import ApiClient from "auth/client/ApiClient";
import TokenClient from "auth/client/TokenClient";
import { AuthContext } from "auth/AuthProvider";
import { useHistory } from "react-router-dom";
import { getPrivacyCookie } from "page-components/Privacy/Consent";

const RegisterForm = () => {
  const form = useRef(null);
  const { t } = useTranslation();
  const [message, setMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { setToken } = useContext(AuthContext);
  const history = useHistory();
  const privacyConsent = getPrivacyCookie() !== undefined;

  // eslint-disable-next-line no-unused-vars
  const onSubmitRegisterForm = (_) => {
    form.current.submit();
  };

  if (!window["onSubmitRegisterForm"]) {
    window["onSubmitRegisterForm"] = onSubmitRegisterForm;
  }

  const onSubmitHandler = async (event) => {
    const formData = new FormData(event.target);
    const body = {};

    formData.forEach((value, key) => {
      body[key] = value;
    });
    event.preventDefault();

    setMessage(null);
    setSubmitting(true);
    await new ApiClient(null)
      .Register(body)
      .then(() => {
        return new TokenClient()
          .Auth({
            username: body["username"],
            password: body["password"],
          })
          .then((response) => {
            setSubmitting(false);
            setToken(response.access_token);
            history.push("/estimate-survival");
          });
      })
      .catch(() => {
        setMessage("Something went wrong while registering");
        setSubmitting(false);
      });
  };
  const formDisabled = !privacyConsent || submitting;
  return (
    <form ref={form} onSubmit={onSubmitHandler}>
      {!privacyConsent && (
        <p>
          {t("This form is protected against robots")}.
          {t(
            "If you wish to use it, you will have to accept the cookie consent"
          )}
          .
        </p>
      )}
      <Fieldset disabled={formDisabled} size="md">
        <InputGroup>
          <Label htmlFor="firstname">{t("Firstname")}</Label>
          <Input name="first_name" required />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="firstname">{t("Lastname")}</Label>
          <Input name="last_name" required />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="register_email">{t("Email")}</Label>
          <Input name="email" id="register_email" type="email" required />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="register_password">{t("Password")}</Label>
          <Input
            name="password"
            id="register_password"
            type="password"
            required
          />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="profession">{t("Profession")}</Label>
          <Input name="profession" id="profession" required />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="specialty">
            {t("If physician, what specialty")}?
          </Label>
          <Input name="specialty" id="specialty" />
        </InputGroup>

        <InputGroup>
          <Label htmlFor="company">{t("Department or company")}?</Label>
          <Input name="company" id="company" />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="account_language">{t("Language")}</Label>
          <Radio
            label={t("Swedish")}
            type="radio"
            id="swe"
            name="account_language"
            value="swe"
            required
          />
          <Radio
            label={t("English")}
            id="eng"
            name="account_language"
            value="en"
          />
        </InputGroup>
        <Button
          type="submit"
          className="g-recaptcha"
          data-sitekey={sitekey}
          data-callback="onSubmitRegisterForm"
          data-action="submit"
        >
          {t("Submit")}
        </Button>
        {message && <Description color="red">{t(message)}</Description>}
      </Fieldset>
    </form>
  );
};

export default RegisterForm;
