import "formdata-polyfill";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Fieldset, InputGroup, Title, Label, Input } from "ui/";
import ApiClient from "auth/client/ApiClient";
import styles from "./resetForm.module.scss";

const ResetComplete = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.inputContainer}>
      <Title>{t("You've got mail")}!</Title>
      <p>
        {t("We’ve just sent a password reset email to you")}.{" "}
        {t("Use the link in that email to reset your password")}.
      </p>
    </div>
  );
};

const ResetPasswordForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (event) => {
    const formData = new FormData(event.target);
    const body = {};

    formData.forEach((value, key) => {
      body[key] = value;
    });

    event.preventDefault();
    setSubmitting(true);
    try {
      await new ApiClient(null).ForgotPassword(body);
    } catch (error) {
      //do nothing
    }
    setSubmitted(true);
  };

  return submitted ? (
    <ResetComplete />
  ) : (
    <div className={styles.inputContainer}>
      <Title>{t("Forgot password")}?</Title>
      <form onSubmit={onSubmit} id="caseform">
        <Fieldset disabled={submitting}>
          <InputGroup>
            <Label htmlFor="email">{t("Email Address")}</Label>
            <Input type="email" id="email" name="email" />
          </InputGroup>
          <Button type="submit">{t("Reset Password")}</Button>
        </Fieldset>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
