import React from "react";
import styles from "./inputWrapper.module.scss";

const InputWrapper = ({ children, nomargin = false }) => {
  return (
    <div
      className={nomargin ? styles.inputWrapperNoMargin : styles.inputWrapper}
    >
      {children}
    </div>
  );
};

export default InputWrapper;
