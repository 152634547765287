import i18n, { supportedLngs } from "./Language";
import React, { createContext, useEffect, useState } from "react";
export const LanguageContext = createContext({
  userLanguage: "en-US",
  userLanguageChange: null,
});

const LanguageProvider = ({ children }) => {
  const defaultLanguage = window.localStorage.getItem("18nextLng");
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || "en-US");
  const provider = {
    userLanguage,
    userLanguageChange: (selected) => {
      const newLanguage = supportedLngs.find((s) => s.code === selected)
        ? selected
        : "en-US";
      setUserLanguage(newLanguage);

      localStorage.setItem("18nextLng", newLanguage);
      i18n.changeLanguage(newLanguage);
    },
  };

  useEffect(() => {
    if (document.documentElement.lang !== userLanguage) {
      document.documentElement.lang = userLanguage;
    }
  }, [userLanguage]);

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;
