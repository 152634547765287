import React from "react";
import styles from "./mobileMenuItem.module.scss";
import { Link } from "react-router-dom";

const MobileMenuItem = ({ children, path }) => {
  return (
    <Link to={path}>
      <li className={styles.mobileMenuItem}>{children}</li>
    </Link>
  );
};

export default MobileMenuItem;
