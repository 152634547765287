import React from "react";
import styles from "./description.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

const Description = ({ children, color }) => {
  const DescriptionClassNames = classNames(styles.description, {
    [styles.grey]: color === "grey",
    [styles.red]: color === "red",
  });
  return <span className={DescriptionClassNames}>{children}</span>;
};

Description.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(["grey", "red"]),
};

export default Description;
