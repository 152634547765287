import "formdata-polyfill";
import React, { useRef } from "react";
import styles from "./shareCaseForm.module.scss";
import countryList from "react-select-country-list";
import RangeSlider from "./RangeSlider/RangeSlider";
import { useTranslation } from "react-i18next";
import { sitekey } from "page-components/recaptcha/recaptcha";
import { Button, ImageUpload, Fieldset } from "ui/";
import ApiClient from "auth/client/ApiClient";
import { Label, Input, Select, InputGroup } from "ui/Form";
import { getPrivacyCookie } from "page-components/Privacy/Consent";

const ShareCaseForm = () => {
  const [value, setValue] = React.useState("");
  const [message, setMessage] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const options = React.useMemo(() => countryList().getData(), []);
  const { t } = useTranslation();
  const changeHandler = (value) => {
    setValue(value);
  };
  const form = useRef(null);
  const privacyConsent = getPrivacyCookie() !== undefined;
  // eslint-disable-next-line no-unused-vars
  const onSubmitShareCaseForm = (_) => {
    form.current.submit();
  };

  if (!window["onSubmitShareCaseForm"]) {
    window["onSubmitShareCaseForm"] = onSubmitShareCaseForm;
  }

  const handleSubmit = async (event) => {
    const formData = new FormData(event.target);

    event.preventDefault();

    setMessage(null);
    setSubmitting(true);
    await new ApiClient(null)
      .SubmitInterestingCase(formData)
      .then(() => {
        setMessage("Någonting gick fel vid registrering");
      })
      .catch((error) => {
        setMessage(error);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  const formDisabled = !privacyConsent || submitting;

  return (
    // @ts-ignore
    <form
      className={styles.niceForm}
      id="caseform"
      ref={form}
      onSubmit={handleSubmit}
      encType="multipart/form-data"
    >
      {!privacyConsent && (
        <p>
          {t("This form is protected against robots")}.
          {t(
            "If you wish to use it, you will have to accept the cookie consent"
          )}
          .
        </p>
      )}
      {message && <p>{message}</p>}
      <Fieldset disabled={formDisabled}>
        <InputGroup>
          <Label htmlFor="name">{t("Name")}</Label>
          <Input name="name" id="name" />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="email">{t("Email")}</Label>
          <Input name="email" id="email" type="email" />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="locationSelector">{t("Location")}</Label>
          <Select
            id="locationSelector"
            value={value}
            onChange={changeHandler}
            variant="outlined"
            name="location_country"
            style={{ width: "100%", marginBottom: "1rem" }}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="oncologic-diagnosis">
            {t("Oncologic Diagnosis")}
          </Label>
          <Input name="oncologic_diagnosis" id="oncologic-diagnosis" />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="survivalRange">
            {t("Applicable Survival Range")}
          </Label>
          <RangeSlider name="survival_range" />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="description-of-procedure">
            {t("Description of Procedure")}
          </Label>
          <Input name="description_procedure" id="description-of-procedure" />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="surgical-adjuvants">{t("Surgical Adjuvants")}</Label>
          <Input name="surgical_adjuvants" id="surgical-adjuvants" />
        </InputGroup>
        <InputGroup>
          <Label htmlFor="imageUpload">{t("Image Upload")}</Label>
          <ImageUpload id="imageUpload" name="images" />
          <p style={{ color: "red" }}>
            {t("DO NOT include any identifying information on images")}
          </p>
        </InputGroup>
        <InputGroup>
          <Label htmlFor="implant-manufacturer">
            {t("Implant Manufacturer")}
          </Label>
          <Input name="implant_manufacturer" id="implantManufacturer" />
        </InputGroup>
        <br />
        <br />
        <Button
          type="submit"
          className="g-recaptcha"
          data-sitekey={sitekey}
          data-callback="onSubmitShareCaseForm"
          data-action="submit"
        >
          {t("Submit")}
        </Button>
      </Fieldset>
    </form>
  );
};

export default ShareCaseForm;
