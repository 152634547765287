import React from "react";
import {
  Section,
  Layout,
  Heading,
  Skeleton,
  Paragraph,
  Container,
  PathButton,
  Image,
} from "ui";

const SectionTitleParagraphImageButton = ({ section, position, bgColor }) => {
  return (
    <Section bgColor={bgColor}>
      <Layout position={position}>
        <Container>
          <Image
            imgSrc={
              section ? "https:" + section.image?.fields?.file?.url : undefined
            }
            altTxt={section ? section.image?.fields?.title : "Loading"}
          />
        </Container>
        <Container>
          <Heading>{section ? section.title : <Skeleton />}</Heading>
          <Paragraph color="grey">
            {section ? section.paragraph : <Skeleton />}
          </Paragraph>
          {section && (
            <PathButton path={section.buttonLink}>{section.button}</PathButton>
          )}
        </Container>
      </Layout>
    </Section>
  );
};

export default SectionTitleParagraphImageButton;
