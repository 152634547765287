import "formdata-polyfill";
import React, { useContext, useEffect, useState } from "react";
import styles from "./loginForm.module.scss";
import { useTranslation } from "react-i18next";
import { AuthContext } from "auth/AuthProvider";
import TokenClient from "auth/client/TokenClient";
import { NavLink, useHistory } from "react-router-dom";
import { Button } from "ui/";
import { CircularProgress } from "@material-ui/core";
import { InputGroup, Label, Input, Fieldset, Description } from "ui/Form";
import { getPrivacyCookie } from "page-components/Privacy/Consent";

const LoginForm = () => {
  const [tokenClient, setTokenClient] = useState(null);
  const { setToken } = useContext(AuthContext);
  const [message, setMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const privacyConsent = getPrivacyCookie() !== undefined;

  const handleOnKeyDown = () => {
    if (message) {
      setMessage(null);
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    const formData = new FormData(event.target);
    const body = {};

    formData.forEach((value, key) => {
      body[key] = value;
    });

    setMessage(null);
    setSubmitting(true);

    await tokenClient
      .Auth(body)
      .then((response) => {
        setToken(response.access_token);
      })
      .catch(() => {
        setMessage(t("Unable to sign in"));
      })
      .then(() => {
        setSubmitting(false);
        history.push("/estimate-survival");
      });
    return false;
  };

  useEffect(() => {
    if (!tokenClient) {
      setTokenClient(new TokenClient());
    }
  });
  return (
    <form onSubmit={onSubmit} className={styles.loginForm} id="caseform">
      {!privacyConsent && (
        <>
          <p>
            {t("This form is protected against robots")}.
            {t(
              "If you wish to use it, you will have to accept the cookie consent"
            )}
            .
          </p>
        </>
      )}
      <div className={styles.inputContainer}>
        {submitting ? (
          <SubmittingElement />
        ) : (
          <Fieldset disabled={!privacyConsent || submitting} size="md">
            <InputGroup>
              <Label htmlFor="username">{t("Username")}</Label>
              <Input
                id="username"
                name="username"
                onKeyDown={handleOnKeyDown}
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor="password">{t("Password")}</Label>
              <Input
                type="password"
                id="password"
                name="password"
                onKeyDown={handleOnKeyDown}
              />
            </InputGroup>
            {message && <Description>{message}</Description>}
            <Button type="submit">{t("Login")}</Button>
          </Fieldset>
        )}
      </div>
      <p>
        <NavLink to={"/forgot-password"}>{t("Forgot password")}?</NavLink>
      </p>
      <p>
        <NavLink to={"/register"}>{t("Register")}?</NavLink>
      </p>
    </form>
  );
};

export default LoginForm;

export const SubmittingElement = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.submitting}>
      <h2 className={styles.subTitle}>{t("Signing you in")}</h2>
      <br />
      <br />
      <CircularProgress size={68} />
      <br />
      <br />
    </div>
  );
};
