import React, { useEffect } from "react";
import styles from "./basepage.module.scss";
import { sendToGTM } from "page-components/analytics/GoogleTagManager";
import Footer from "page-components/Footer/Footer";
import Header from "page-components/Header/Header";
import Consent, { getPrivacyCookie } from "page-components/Privacy/Consent";
import { Layout, Paragraph, Section, Skeleton, Title } from "ui";

export const BasePageSection = ({ page, children = undefined }) => {
  return (
    <Section bgColor="lightWhite">
      <Layout narrow={true} position="topCenter">
        <Title>{page ? page?.pageTitle : <Skeleton />}</Title>
        <Paragraph color="purple">
          {page ? page?.pageDescription : <Skeleton />}
        </Paragraph>
        {children}
      </Layout>
    </Section>
  );
};

const BasePage = (props) => {
  useEffect(() => {
    document.title = props.title + " - PathFX";
    if (getPrivacyCookie()) {
      sendToGTM({
        dataLayerName: "dataLayer",
        data: {
          event: "pageview",
          page: {
            url:
              document.location.origin +
              document.location.pathname +
              document.location.search,
            title: document.title,
          },
        },
      });
    }
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props?.match?.path]);

  return (
    <div className={styles.basepage}>
      <Header homeState={props.home} />
      <main role="main">{props.component}</main>
      <Footer />
      <Consent />
    </div>
  );
};

export default BasePage;
