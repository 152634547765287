import { useEffect, useState } from "react";

export const sitekey = "6LfJLakZAAAAAJDXOCmcjSBTknzKnrESBXf8fhZI";

const Recaptcha = () => {
  const [recaptchaInitialized, setRecaptchaInitialized] = useState(false);

  if (recaptchaInitialized) {
    initRecaptcha();
  }

  useEffect(() => {
    if (
      !recaptchaInitialized &&
      !document.location.origin.includes("localhost")
    ) {
      setRecaptchaInitialized(true);
    }
  });
  return null;
};

function initRecaptcha() {
  document.head.insertBefore(getScript(), document.head.childNodes[0]);
}

function getScript() {
  const script = document.createElement("script");
  script.src = "https://www.google.com/recaptcha/api.js";
  return script;
}

export default Recaptcha;
