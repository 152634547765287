import React from "react";
import PropTypes from "prop-types";
import styles from "./heroTitleSecondary.module.scss";
import classNames from "classnames";

const HeroTitleSecondary = ({ children, color }) => {
  const HeroTitleSecondaryClassNames = classNames(styles.heroTitleSecondary, {
    [styles.white]: color === "white",
    [styles.purple]: color === "purple",
  });
  return <h2 className={HeroTitleSecondaryClassNames}>{children}</h2>;
};

HeroTitleSecondary.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(["white", "purple"]),
};

export default HeroTitleSecondary;
