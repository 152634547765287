import React from "react";
import {
  Section,
  Layout,
  Heading,
  Skeleton,
  Paragraph,
  Container,
  Image,
  ImageContainer,
} from "ui";

const SectionTwoTitlesTwoImagesTwoParagraphs = ({ section, bgColor }) => {
  return (
    <Section bgColor={bgColor}>
      <Layout position="left">
        <Container>
          {section.firstLink ? (
            <ImageContainer
              imgSrc={
                section
                  ? "https:" + section.firstImage?.fields?.file?.url
                  : undefined
              }
              href={section.firstLink}
            />
          ) : (
            <Image
              imgSrc={
                section
                  ? "https:" + section.firstImage?.fields?.file?.url
                  : undefined
              }
              altTxt={section ? section.firstImage?.fields?.title : "Loading"}
            />
          )}
          <Heading>{section ? section.firstTitle : <Skeleton />}</Heading>
          <Paragraph color="grey">
            {section ? section.firstParagraph : <Skeleton />}
          </Paragraph>
        </Container>

        <Container>
          {section.secondLink ? (
            <ImageContainer
              imgSrc={
                section
                  ? "https:" + section.secondImage?.fields?.file?.url
                  : undefined
              }
              href={section.secondLink}
            />
          ) : (
            <Image
              imgSrc={
                section
                  ? "https:" + section.secondImage?.fields?.file?.url
                  : undefined
              }
              altTxt={section ? section.secondImage?.fields?.title : "Loading"}
            />
          )}

          <Heading>{section ? section.secondTitle : <Skeleton />}</Heading>
          <Paragraph color="grey">
            {section ? section.secondParagraph : <Skeleton />}
          </Paragraph>
        </Container>
      </Layout>
    </Section>
  );
};

export default SectionTwoTitlesTwoImagesTwoParagraphs;
